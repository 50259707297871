import React, { useState, useEffect } from 'react';
import './FixedBottomBar.css';
import { useNavigate } from 'react-router-dom';

const FixedBottomBar = () => {
  const navigate = useNavigate();
  const [color, setColor] = useState('blue'); // Initialize with your preferred starting color

  useEffect(() => {
    const colors = ['blue', 'green', 'red', 'orange', 'purple']; // List of colors to cycle through
    let index = 0;

    const colorInterval = setInterval(() => {
      setColor(colors[index]);
      index = (index + 1) % colors.length; // Cycle through the colors
    }, 1000); // Change color every 2 seconds

    return () => clearInterval(colorInterval); // Cleanup on unmount
  }, []);

  const handleGetQuoteClick = () => {
    navigate('/contact');
  };

  return (
    <div className="fixed-bottom-bar">
      <button className="get-quote-btn" onClick={handleGetQuoteClick}>
        Let's get started <br /> GET A QUOTE
      </button>
      <a href="tel:02079936150" className="call-us-btn" style={{ background: color }}>
        Call Us Now: 02079936150
      </a>
    </div>
  );
};

export default FixedBottomBar;
