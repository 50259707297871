
import React from 'react';
import Service from './Service';

import logo from '../../images/title_vector.png'

//images
import business_cash_advance from '../../images/products/business_cash_advance.avif';
import servicesBanner from '../../images/products/product_banner.png';
import asset_finance from '../../images/products/asset_finance.png';
import motatgeLoan from '../../images/loan/mortage_loan.jpeg';
import autoLoan from '../../images/products/revolving_credit.avif';
import invoicefinance from '../../images/products/invoicefinance.png';
import unsecuredLoan from '../../images/services/unsecured_loan_banner.png';
//components
import HowItWorks from '../../components/Home/HowItWorks';
import LoanCalculator from '../About/LoanCalculator';
import Banner from '../../components/Banner';
import ScrollToTopOnMount from '../ScrollToTop';


const servicesData = [
    {
        image: unsecuredLoan,
        title: 'Unsecured Business Finance',
        description: 'Simple, fast and low-cost loans for SMEs looking to finance their businesses without putting their assets at risk. Opt for greater flexibility and small fixed monthly repayment options under Unsecured Business Finance.',
        path:"/services/unsecured_business_finance"
    },
    {
        image: business_cash_advance,
        title: 'Business Cash Advance',
        description: 'Short-term cash advance for SMEs without fixed payments, terms, or any security. Borrow from £5,000 to £200,000 and pay back the advances with a small percentage from your future credit/debit sales.',
        path:"/services/business_cash_advance",
    },
    {
        image: motatgeLoan,
        title: 'Property Finance',
        description: 'For commercial, residential, or hybrid-land use, property finance ensures all the cost borne for land, development, or refurbishment is easily achievable. Explore the best property finance options for your venture.',
        path:"/services/property_finance",
    },
    {
        image: autoLoan,
        title: 'Revolving Credit Facility',
        description: 'Revolving Credit Facility is designed to support you with managing your cash flow and there is a facility to draw at any time and you can only pay interest when you use it. It is a flexible funding solution offering a market leading rate starting from 0.05% per day.',
        path:"/services/revolving_credit_facility",
    },
    {
        image: invoicefinance,
        title: 'Invoice Finance',
        description: 'Use unpaid invoices to receive immediate cash Invoice finance is a novel type of loan that helps businesses leverage their due invoices by lenders and provides quick monetary support for SMEs for their business expenditures.',
        path:"/services/invoice_finance",
    },
    {
        image: asset_finance,
        title: 'Asset Finance',
        description: ' Access easy and simplified cash flow to acquire business legitimate tools (vehicles & equipment) through Asset Finance. No need to outright put all your money on buying assets. Rent and let them pay for themselves.',
        path:"/services/asset_finance",
    },
];



const Services = () => {
    return (
        <div className=" mx-auto py-4 mt-[4rem]">
            <ScrollToTopOnMount/>
            <Banner name={'Products'} bannerImg={servicesBanner}/>

            <div className=' mx-auto px-[1rem] sm:px-[0.5rem] md:px-[2rem] lg:px-[5rem] my-4 py-[2rem]'>
                <div className='flex gap-2 mx-auto w-fit'>
                    <img
                        src={logo}
                        className=" w-8 h-8"
                        alt="Title Vector"
                    />
                    <h1 className="text-3xl mb-10 font-bold text-center text-darkYellow">Our Products</h1>

                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    {servicesData.map((service, index) => (
                        <Service
                            key={index}
                            image={service.image}
                            title={service.title}
                            description={service.description}
                            path={service.path}
                        />
                    ))}
                </div>
            </div>

            <HowItWorks />
            <LoanCalculator />

        </div>
    );
};

export default Services;
