import React from 'react';
import errorPage from '../images/error_page.png';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className='mt-[8rem]'>
       
      <div className='flex justify-center items-center flex-col my-6'>
        <img src={errorPage} alt="404" className='w-[43rem]'/>
        <h1 className=' text-darkYellow text-3xl font-bold'>Page Not Found</h1>
        <p>Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
        <Link to={'/'}>
        <button className='bg-darkYellow mt-5 text-white hover:text-darkYellow border-2 border-darkYellow duration-500 px-12 py-4 rounded-full hover:bg-white'>Back To Home</button>
        </Link>
        
      </div>
    </div>
  );
}

export default PageNotFound;
