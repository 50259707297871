import React from 'react';

const LoanCalculatorTable = ({monthlyPayment,totalInterest,totalPayble,principal,rate,time}) => {

  // console.log(totalPayable);
  
 

  return (
    <div className=' ' style={{ padding: '20px' }}>
      <h2 className='text-left text-darkYellow font-bold text-xl md:text-2xl border-b-2 border-darkYellow mx-auto'>Result </h2>
      <p className='text-gray-600'>The table below shows how your quote would appear as other commonly used rate types.</p>
      <table className='mx-auto sm:w-[100%]' style={{  borderCollapse: 'collapse', marginTop: '20px' }}>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>Loan Amount</td>
            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>
              {/* £{loanDetails.loanAmount.toLocaleString('en-UK', { minimumFractionDigits: 2 })} */}
              £{principal}
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>Loan Term</td>
            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>
              {time} Months
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>Loan Rate</td>
            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>
              {rate} %
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>Monthly Payment Amount</td>
            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>
              {/* £{monthlyPayment.toLocaleString('en-UK', { minimumFractionDigits: 2 })} */}
              £ {monthlyPayment?.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>Total Interest Amount</td>
            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>
              {/* £{loanDetails.totalInterest.toLocaleString('en-UK', { minimumFractionDigits: 2 })} */}
              £ {totalInterest?.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className='font-bold' style={{ border: '1px solid #ddd', padding: '8px' }}>Total Payable</td>
            <td className='font-bold' style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right', fontWeight: 'bold' }}>
              {/* £{loanDetails.totalPayable.toLocaleString('en-UK', { minimumFractionDigits: 2 })} */}
              £ {totalPayble?.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LoanCalculatorTable;
