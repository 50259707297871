// src/App.js
import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Navbar from './Footer section/Navbar/Navbar';
import { Route, Routes } from 'react-router-dom';
//components
import Home from './Static Pages/Home Page/Home';
import Contact from './Static Pages/Contact Page/Contact'
import Footer from './Footer section/Footer/Footer';
import PageNotFound from './Static Pages/PageNotFound';
import About from './Static Pages/About/About';
import Services from './Static Pages/Services/Services';
import Blogs from './Static Pages/Blogs/Blogs';
import UnsecruedFinance from './Static Pages/Services/services detail/UnsecruedFinance';
import BusinessCashAdvance from './Static Pages/Services/services detail/BusinessCashAdvance';
import PropertyFinance from './Static Pages/Services/services detail/PropertyFinance';
import RevolvingCreditFacility from './Static Pages/Services/services detail/RevolvingCreditFacility';
import AssetFinance from './Static Pages/Services/services detail/AssetFinance';
import InvoiceFinance from './Static Pages/Services/services detail/InvoiceFinance';
import Calculator from './Static Pages/Calculator/Calculator';
import PrivacyPolicy from './Static Pages/Privacy_policy/PrivacyPolicy';
import TermsOfService from './Static Pages/Privacy_policy/TermsOfService';
import Cookies_Policy from './Static Pages/Privacy_policy/Cookies_Policy';
import ScrollCalculator from './Static Pages/Calculator/ScrollCalculator';


function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} />
        <Route path='/blog' element={<Blogs />} />
        {/* <Route path='/calculator' element={<Calculator />} /> */}
        <Route path='/calculator' element={<ScrollCalculator />} />
        <Route path='/services/unsecured_business_finance' element={<UnsecruedFinance />} />
        <Route path='/services/business_cash_advance' element={<BusinessCashAdvance />} />
        <Route path='/services/property_finance' element={<PropertyFinance />} />
        <Route path='/services/revolving_credit_facility' element={<RevolvingCreditFacility />} />
        <Route path='/services/asset_finance' element={<AssetFinance />} />
        <Route path='/services/invoice_finance' element={<InvoiceFinance />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/cookies-policy' element={<Cookies_Policy />} />
        <Route path='/term-of-services' element={<TermsOfService />} />



        <Route path='*' element={<PageNotFound />} />

      </Routes>
      <Footer />

      <div>

        <FloatingWhatsApp
          // phoneNumber="+442079936150"
          phoneNumber="+447450191028"
          accountName="Maestro"
          allowEsc
          allowClickAway
          notification
          notificationSound
        />

      </div>

    </>
  );
}

export default App;
