import React, { useEffect, useState } from 'react';
import LoanCalculatorTable from './LoanCalculatorTable';

//
import { IoMdAddCircle } from "react-icons/io";
import { RiIndeterminateCircleFill } from "react-icons/ri";
import Banner from '../../components/Banner';
import servicesBanner from '../../images/products/calculator.png';

const ScrollCalculator = () => {
    // State variables
    const [principal, setPrincipal] = useState(1000);
    const [rate, setRate] = useState(5.0);
    const [time, setTime] = useState(12);
    const [monthlyPayments, setMonthlyPayments] = useState(0);
    const [totalIntrest, setTotalIntrest] = useState(0);
    const [totalPayble, setTotalPayble] = useState(0);

    // Function to calculate interest
    const calculateInterest = (principal, rate, time) => {
        return (principal * rate * time) / 100;
    };

    // Calculated interest
    const interest = calculateInterest(principal, rate, time);

    const calculateMonthlyPayment = (loanAmount, annualInterestRate, loanTermMonths) => {
        const monthlyInterestRate = rate / 12 / 100;
        setMonthlyPayments((principal * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -time)));
    };
    const calculateTotalInterest = () => {
        // setTotalIntrest((monthlyPayment * loanTermMonths) - loanAmount)
        setTotalIntrest((monthlyPayments * time) - principal)
    };
    const calculateTotalPayable = (loanAmount, totalInterest) => {
        // setTotalPayble(loanAmount + totalInterest)
        setTotalPayble(monthlyPayments * time)
    };
    useEffect(() => {
        calculateMonthlyPayment(principal, rate, time);
        // calculateTotalInterest(monthlyPayments, time, principal);
        calculateTotalInterest();
        calculateTotalPayable(principal, totalIntrest);
    }, [principal, time, rate, setTotalPayble, setTotalIntrest]);

    return (
        <div className=' my-[5rem] py-6' >
            <Banner
                name="Rate Comparison Tool"
                bannerImg={servicesBanner}
            />
            {/* <h2 className='text-darkYellow font-bold text-2xl text-center'> Calculate Your Loan</h2> */}
            <h2 className='text-darkYellow font-bold text-2xl md:text-4xl text-center mt-10'> Rate Comparison Tool</h2>
            <p className=' text-lg md:text-xl text-gray-600 text-center'>Compare the cost of your business loan quotes with this handy rate comparison tool</p>

            <div className='mx-[1rem] md:mx-[5rem] my-6'>
                <h2 className='text-xl font-semibold'>Compare business loan rates</h2>
                <p className='text-gray-600'>At Mestro, we believe business loans should be priced fairly and transparently. As the majority of SME lending in the UK is unregulated, commercial finance providers aren't obliged to disclose an annual interest rate or comparable rate of funding. With lenders presenting business loan rates in different ways, SMEs can find it difficult to compare quotes. As a result, many businesses are paying more than they think for their finance.</p>
                <p className='text-gray-600'>Using our rate comparison tool, SMEs can compare business loans that are based on different rates, and discover the true cost of their quotes.</p>
            </div>


            <div className='flex flex-col md:flex-row justify-center mx-[1rem] md:mx-[8rem]'>
                <div className=' mx-auto border shadow-md w-[100%] md:w-[50%]'>
                    <div className=' w-full sm:w-[80%] mx-auto gap-5 p-5'>
                        <p className='text-xl font-semibold text-darkYellow'>Loan Amount  : <span className='text-gray-950'>£ {principal}</span> </p>
                        <div className='my-3'>
                            <div className='flex justify-between w-[100%]'>
                                <RiIndeterminateCircleFill
                                    onClick={() => { setPrincipal(principal - 100) }}
                                    className='text-darkYellow text-2xl cursor-pointer'
                                />
                                <IoMdAddCircle
                                    onClick={() => { setPrincipal(principal + 100) }}
                                    className='text-darkYellow text-2xl cursor-pointer'
                                />
                            </div>

                            <div className='w-[100%] '>
                                <input
                                    type="range"
                                    min="1000"
                                    max="500000"
                                    step="100"
                                    className='w-full'
                                    value={principal}
                                    onChange={(e) => setPrincipal(Number(e.target.value))}
                                />
                            </div>
                            <div className='flex justify-between w-[100%] '>
                                <p className='font-semibold '>£ 1000</p>
                                <p className='font-semibold '>£ 500,000</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full sm:w-[80%] mx-auto gap-5 p-5'>
                        {/* <label>Loan Amount ($): {principal}</label> */}
                        <p className='text-xl font-semibold text-darkYellow'>Rate (%):<span className='text-gray-950 mx-4'> {rate}%</span> </p>
                        <div className='my-3'>

                            <div className='flex justify-between w-[100%]'>
                                <RiIndeterminateCircleFill
                                    onClick={() => { setRate(rate - 0.1) }}
                                    className='text-darkYellow text-2xl cursor-pointer' />
                                <IoMdAddCircle
                                    onClick={() => { setRate(rate + 0.1) }}
                                    className='text-darkYellow text-2xl cursor-pointer'
                                />
                            </div>

                            <div className='w-[100%] '>
                                <input
                                    type="range"
                                    min="1.0"
                                    max="10.0"
                                    step="0.1"
                                    value={rate}
                                    className='w-full'
                                    onChange={(e) => setRate(Number(e.target.value))}
                                />
                            </div>
                            <div className='flex justify-between w-[100%]'>
                                <p className='font-semibold '>1.0%</p>
                                <p className='font-semibold '>10.0%</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full sm:w-[80%] mx-auto gap-5 p-5'>
                        {/* <label>Loan Amount ($): {principal}</label> */}
                        <p className='text-xl font-semibold text-darkYellow'>Time (months): <span className='text-gray-950'> {time}</span> </p>
                        <div className='my-3'>

                            <div className='flex justify-between w-[100%]'>
                                <RiIndeterminateCircleFill
                                    onClick={() => { setTime(time - 1) }}
                                    className='text-darkYellow text-2xl cursor-pointer'
                                />
                                <IoMdAddCircle
                                    onClick={() => { setTime(time + 1) }}
                                    className='text-darkYellow text-2xl cursor-pointer' />
                            </div>
                            <div className='w-[100%] '>
                                <input
                                    type="range"
                                    min="1"
                                    max="120"
                                    step="1"
                                    value={time}
                                    className='w-full'
                                    onChange={(e) => setTime(Number(e.target.value))}
                                />
                            </div>
                            <div className='flex justify-between w-[100%] '>
                                <p className='font-semibold'>1</p>
                                <p className='font-semibold'>120</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' w-full md:w-[50%]'>
                    {/* <h3>Calculated Interest: ${interest.toFixed(2)}</h3> */}
                    <LoanCalculatorTable
                        monthlyPayment={monthlyPayments}
                        totalInterest={totalIntrest}
                        totalPayble={totalPayble}
                        principal={principal}
                        rate={rate}
                        time={time}
                    />
                </div>

            </div>

            <div className=' mx-[1rem] md:mx-[7rem] my-12'>
                <h2 className=' text-xl md:text-3xl text-darkYellow font-bold'>Loan Rates</h2>
                <h3 className='text-lg font-bold'>Annual interest rate</h3>
                <p className='text-gray-600 my-4'>The annual rate that is charged on a loan. Expressed as a percentage, it represents the actual yearly cost of funding over the term of the loan. Annual interest is calculated by multiplying the monthly rate charged on the loan by the number of payment periods in a year, i.e. Annual interest rate = monthly rate x 12.</p>

                <h2 className=' text-xl md:text-3xl text-darkYellow font-bold'>Why did we create this tool?</h2>
                <p className='text-gray-600 my-4'>We believe it’s important that businesses know exactly how much they’ll be paying before committing to a loan. With lenders presenting their business loan rates in different ways, it creates confusion around the cost of business finance,making it hard for businesses to compare business loan quotes from competing lenders. This rate comparison tool is designed to offer some much-needed clarity on the price of SME finance, and ensure that business owners make the best funding decision for their company.</p>
            </div>




        </div>
    );
};

export default ScrollCalculator;
