import React from 'react';
import { Link } from 'react-router-dom';

import vector_title from '../../images/title_vector.png'
import calculatorImage from '../../images/services/calculotor.png';

const LoanCalculator = () => {



       return (
        <div>
            <div className="grid lg:grid-cols-2 px-[1rem] sm:px-[4rem] py-[3rem] bg-[#f9f9f9]">
               
                <div className="loan-info">
                    <div className="w-full flex mb-4 ">
                        <img
                            src={vector_title}
                            className="mr-4 mt-1 w-6 h-6"
                            alt="Title Vector"
                        />
                        <h4 className="text-lg lg:text-2xl font-semibold text-darkYellow"> Calculators
                        </h4>
                    </div>
                    <h1 className=' font-semibold'>Empower Yourself with Accurate Loan Estimates</h1>
                    <p>
                        Our loan calculators are powerful tools designed to assist you in making informed financial decisions. Whether you’re planning a major purchase, estimating monthly payments, or comparing loan options, our calculators are here to help.
                    </p>
                    <div className='listy-items'>
                        <ul className=''>
                            <li>Loan Payment Calculator</li>
                            {/* <li>Affordability Calculator</li> */}
                        </ul>
                        <ul className='ms-10'>
                            {/* <li>Amortization Calculator</li> */}
                            <li>Interest Calculator</li>
                        </ul>
                    </div>
                    <Link to='/calculator'>
                    <button className="px-6 lg:px-8 py-3 font-medium bg-darkYellow hover:bg-white hover:text-darkYellow rounded-full text-white transition-all duration-200 ease-in" 
                    style={{ border:'2px solid #ff9408' }}>Open Calculator ➔</button>
                    </Link>
                </div>

                {/* <div className='flex justify-center items-center'> */}
                <div className=''>
                    <img src={calculatorImage} alt="Calculator Cartoon " />
                </div>
            </div>
        </div>
    );
}

export default LoanCalculator;
