import React, { useState } from 'react';
import { motion } from 'framer-motion';

//icons
import { FaStar, FaRegStar } from "react-icons/fa6";
import { RiDoubleQuotesR } from "react-icons/ri";

function wordShortner(word, length) {
    if (word.length <= length) {
        return word;
    }
    return word.slice(0, length) + "...";
}

const TestimonialCard = ({ testimonial }) => {
    const [show, setShow] = useState(false);
    function toggleShow() {
        setShow(!show);
    }
    return (
        <motion.div
            viewport={{ once: true }}
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
                delay: 0.4,
                y: { type: "spring", stiffness: 60 },
                opacity: { duration: 0.2 },
                ease: "easeIn",
                duration: 1
            }}
            className=' bg-green-900 grid grid-cols-1 mx-2 min-h-[20rem] rounded-lg border border-darkYellow '>
            {/* <div className="bg-[#9c8d793b] rounded-lg shadow-lg p-6 flex flex-col "> */}
            <div className="bg-yellow-50  rounded-lg shadow-lg p-6 flex flex-col ">
                {/* <div className='flex gap-1'>
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaRegStar />
                </div> */}
                {
                    testimonial?.Rating===5 &&  
                    <div className='flex gap-1'>
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    {/* <FaRegStar /> */}
                    </div>

                }
                {
                    testimonial?.Rating===4 &&  
                    <div className='flex gap-1'>
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaRegStar className='text-gray-500'/>
                    </div>

                }
                {
                    testimonial?.Rating===3 &&  
                    <div className='flex gap-1'>
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaRegStar className='text-gray-500'/>
                    <FaRegStar className='text-gray-500'/>
                    </div>

                }
                {
                    testimonial?.Rating===2 &&  
                    <div className='flex gap-1'>
                    <FaStar className=' text-yellow-500' />
                    <FaStar className=' text-yellow-500' />
                    <FaRegStar className='text-gray-500'/>
                    <FaRegStar className='text-gray-500'/>
                    <FaRegStar className='text-gray-500'/>
                    </div>

                }
                {
                    testimonial?.Rating===1 &&  
                    <div className='flex gap-1'>
                    <FaStar className=' text-yellow-500' />
                    <FaRegStar className='text-gray-500'/>
                    <FaRegStar className='text-gray-500'/>
                    <FaRegStar className='text-gray-500'/>
                    <FaRegStar className='text-gray-500'/>
                    </div>

                }

                <p className="my-4 text-gray-600 text-base">
                    {/* {testimonial?.testimonial} */}
                    {
                        show === true ? testimonial?.testimonial : wordShortner(testimonial?.testimonial, 200)
                    }
                    {
                        testimonial.testimonial.length > 200 && 
                        <span className='text-blue-600 cursor-pointer' onClick={toggleShow}>{show===false?"Read More":"Hide"}</span>
                    }
                </p>

                <div className="flex items-center ">
                    <img src={testimonial.image} alt={testimonial?.name} className="w-16 h-16 rounded-full mr-4" />
                    <div>
                        <h3 className="text-xl font-semibold">{testimonial.name}</h3>
                        <p className="text-sm text-gray-500">{testimonial.role}</p>
                    </div>
                    <RiDoubleQuotesR className='text-slate-400 text-6xl opacity-85' />
                </div>
            </div>
        </motion.div>

    );
};


export default TestimonialCard;
