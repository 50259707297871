import React from 'react';
import "../Home Page/Home.css";
import WhyChooseus from './WhyChooseus';
import TestimonialSlider from '../../components/Home/Testimonial';
// import LoanCalculator from './LoanCalculator';
import { motion } from 'framer-motion';
import aboutBanner from '../../images/products/aboutBanner.png';
import titleVector from '../../images/title_vector.png'
import AboutImage from '../../images/products/about_Image.avif';
import Banner from '../../components/Banner';
import ScrollToTopOnMount from '../ScrollToTop';

const About = () => {
    return (
        <div className='mt-20'>
            <ScrollToTopOnMount/>
            <Banner name={'About Us'} bannerImg={aboutBanner}/>

            {/* <AboutUs /> */}

            <div className=" md:m-[5rem] py-[2rem] px-[2rem] grid grid-cols-1 lg:grid-cols-2 ">
                <div className="">
                    <motion.div
                        viewport={{ once: true }}
                        initial={{ y: 50, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            delay: 0.4,
                            y: { type: "spring", stiffness: 60 },
                            opacity: { duration: 0.2 },
                            ease: "easeIn",
                            duration: 1
                        }}
                        className="w-full flex gap-4">
                        <img
                            src={titleVector}
                            className=" w-6 h-6"
                            alt="Title Vector"
                        />
                        <p className="text-2xl font-semibold text-darkYellow" >About Us</p>
                    </motion.div>

                    <motion.h1
                        viewport={{ once: true }}
                        initial={{ y: 50, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            delay: 0.4,
                            y: { type: "spring", stiffness: 60 },
                            opacity: { duration: 0.2 },
                            ease: "easeIn",
                            duration: 1
                        }}
                        className='text-4xl text-gray-700 font-bold my-4'>
                        {/* Your Trusted Source for Loan <br /> Reviews and Comparison */}
                        Discover our History
                    </motion.h1>

                    <motion.h4
                     viewport={{ once: true }}
                     initial={{ y: 50, opacity: 0 }}
                     whileInView={{ y: 0, opacity: 1 }}
                     transition={{
                         delay: 0.4,
                         y: { type: "spring", stiffness: 60 },
                         opacity: { duration: 0.2 },
                         ease: "easeIn",
                         duration: 1
                     }}
                     className='text-semibold text-xl my-3'
                    >
                        We are a young and creative company. We offer you unique finance options
                    available.
                    </motion.h4>

                    <motion.p
                        viewport={{ once: true }}
                        initial={{ y: 50, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            delay: 0.4,
                            y: { type: "spring", stiffness: 60 },
                            opacity: { duration: 0.2 },
                            ease: "easeIn",
                            duration: 1
                        }}
                        className=' text-gray-600 text-lg'>
                       
                        Our goal is to be your first choice for business finance needs. We designed our services to make obtaining business financing straightforward, while upholding integrity and delivering exceptional customer service. By partnering with top lenders, we offer a broad array of funding options tailored to your business requirements. Our experienced and skilled team is here to support you, allowing you to concentrate on smoothly running your business. As our partner, you will be kept informed throughout every step of the process.
                    </motion.p>
                    {/* <div className="about-us-features">
                        <motion.div 
                        viewport={{ once: true }}
                        initial={{ y: 50, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            delay: 0.4,
                            y: { type: "spring", stiffness: 60 },
                            opacity: { duration: 0.2 },
                            ease: "easeIn",
                            duration: 1
                        }}
                        className="about-us-feature">
                            <div className="about-us-icon">🔍</div>
                            <h3>Expertise & Objectivity</h3>
                            <p className='text-sm'>With years of experience in <br /> the financial industry</p>
                        </motion.div>
                        <motion.div 
                        viewport={{ once: true }}
                        initial={{ y: 50, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            delay: 0.4,
                            y: { type: "spring", stiffness: 60 },
                            opacity: { duration: 0.2 },
                            ease: "easeIn",
                            duration: 1
                        }}
                        className="about-us-feature">
                            <div className="about-us-icon">💼</div>
                            <h3>Loan Database</h3>
                            <p className='text-sm'>We have curated a comprehensive database <br /> of loan products</p>
                        </motion.div>

                        <motion.div 
                        viewport={{ once: true }}
                        initial={{ y: 50, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            delay: 0.4,
                            y: { type: "spring", stiffness: 60 },
                            opacity: { duration: 0.2 },
                            ease: "easeIn",
                            duration: 1
                        }}
                        className="about-us-feature">
                            <div className="about-us-icon">⚖️</div>
                            <h3>Transparent Comparison</h3>
                            <p className='text-sm'>Our user-friendly loan comparison tools <br /> allow you to effortlessly compare options</p>
                        </motion.div>

                        <motion.div 
                        viewport={{ once: true }}
                        initial={{ y: 50, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            delay: 0.4,
                            y: { type: "spring", stiffness: 60 },
                            opacity: { duration: 0.2 },
                            ease: "easeIn",
                            duration: 1
                        }}
                        className="about-us-feature">
                            <div className="about-us-icon">⭐</div>
                            <h3>Reviews and Ratings</h3>
                            <p className='text-sm'>We pride ourselves on providing unbiased <br /> loan reviews</p>
                        </motion.div>
                    </div> */}
                    {/* <button className="px-6 lg:px-8 py-3 font-medium border-2 border-btnGreen hover:bg-white rounded-full text-white transition-all duration-200 ease-in" style={{ background: '#074C3E' }}>Read More ➔</button> */}
                </div>
                <div className="about-us-illustration">
                    {/* <img src='https://titu.softivus.com/finview/wp-content/uploads/2024/03/about_us.png' alt="Loan Illustration" /> */}
                    <img src={AboutImage} alt="Loan Illustration" />
                </div>
            </div>

            <WhyChooseus />
            {/* <LoanCalculator /> */}
            <TestimonialSlider />
        </div>
    );
}

export default About;
