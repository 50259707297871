import React from 'react';
import { FaRegCircleUser } from "react-icons/fa6";
import { CiCalendarDate } from "react-icons/ci";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

const BlogCard = ({url, title, date, author, excerpt, imageUrl }) => {
  return (
    <div className="bg-white shadow-md min-h-[31rem] border rounded-lg overflow-hidden">
      <div className="p-2 px-4">
        <img src={imageUrl} alt={title} className="w-full h-48 object-cover rounded-md" />
        <h2 className="text-xl font-bold my-2">{title}</h2>
        <div className='flex justify-between mx-3'>
          <p className="text-gray-600 text-sm  flex gap-2" ><CiCalendarDate className='text-lg' />{date} </p>
          {/* <p className='text-gray-600 text-sm flex gap-2'><FaRegCircleUser className='text-lg' /> by <span className='font-semibold'>{author}</span></p> */}
        </div>

        <p className="text-gray-700 my-4 px-2">{excerpt}</p>

        <Link to={url} className=" text-darkYellow font-semibold flex items-center gap-1 hover:gap-2 duration-300 py-2 px-4 rounded">
          Read more <FaLongArrowAltRight />
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
