import React from 'react';
import './Footer.css';
import mastrLogo from '../../images/MASTRO LOGO.png';
import { IoLocationSharp } from "react-icons/io5";
import { LuPhone } from "react-icons/lu";
import { MdEmail } from "react-icons/md";
import { Link } from 'react-router-dom';
import FixedBottomBar from '../../components/contactpopover/FixedBottomBar';

function Footer() {
  return (
    <footer className=" footer px-[1rem] sm:px-[3rem] md:px-[5rem] py-8 bg-slate-800 text-darkYellow">
      <div className="footer-section w-full lg:w-[30%] ">
        <img src={mastrLogo} alt='Logo' className='w-[8rem] my-6' />
        {/* <p>Over 24 years experience and knowledge but the majority suffered alteration barrased many desktop publishing ages.</p> */}
        <p>We are a young and innovative company, dedicated to bringing you a range of unique and flexible finance options. Our goal is to provide you with tailored financial solutions that meet your specific needs and help your business thrive.</p>
        {/* <div className="social-icons mt-5 gap-5">
          <a className='hover:text-darkYellow text-2xl mx-2' href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
          <a className='hover:text-darkYellow text-2xl mx-2' href="https://x.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a className='hover:text-darkYellow text-2xl mx-2' href="https://www.google.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-google-plus"></i>
          </a>
          <a className='hover:text-darkYellow text-2xl mx-2' href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i>
          </a>
          <a className='hover:text-darkYellow text-2xl mx-2 hover:scale-105' href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </div> */}
      </div>


      <div className="footer-section opening-hours lg:pl-[6rem] ">
        <h3 className='text-darkYellow font-bold text-xl my-2 mr-5'>Our Products</h3>
        <ul>
          <Link to={'/services/unsecured_business_finance'}><li className='hover:text-darkYellow cursor-pointer'>Unsecured Business Finance</li></Link>
          <Link to={'/services/business_cash_advance'}><li className='hover:text-darkYellow cursor-pointer'>Business Cash Advance</li></Link>
          <Link to={'/services/property_finance'}><li className='hover:text-darkYellow cursor-pointer'>Property Finance</li></Link>
          <Link to={'/services/revolving_credit_facility'}><li className='hover:text-darkYellow cursor-pointer'>Revolving Credit Facility</li></Link>
          <Link to={'/services/invoice_finance'}><li className='hover:text-darkYellow cursor-pointer'>Invoice Finance</li></Link>
          <Link to={'/services/asset_finance'}><li className='hover:text-darkYellow cursor-pointer'>Asset Finance</li></Link>
        </ul>
      </div>
      <div className="footer-section contact-us w-full  lg:w-[30%]">
        <h3 className='text-darkYellow font-bold text-xl my-2'>Contact us</h3>
        <ul>
          <li className='flex gap-2 '>
            <IoLocationSharp className='text-lg' />
            <a href="https://www.google.com/maps/place/16+Upper+Woburn+Pl,+London+WC1H+0AF,+UK/@51.527016,-0.133215,17z/data=!3m1!4b1!4m6!3m5!1s0x48761b3b61addf4b:0x604a5aa5d380c503!8m2!3d51.5270127!4d-0.1306401!16s%2Fg%2F11rqkyg05r?entry=ttu"
              target='_blank'
              rel='noreferrer'
              className='hover:text-darkYellow'
            >
              16 Upper Woburn Place,<br /> London WC1H 0AF.
            </a>
          </li>
          <li className='flex gap-2 '>
            <MdEmail className='text-lg' />
            <a href="mailto:contact@maestrofinance.co.uk" className='hover:text-darkYellow'>contact@maestrofinance.co.uk </a>
          </li>
          <li className='flex gap-2 '>
            <LuPhone className='text-lg' />
            <a href="tel:+442079936150" className='hover:text-darkYellow'>02079936150</a>
          </li>
        </ul>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Maestro. All Rights Reserved</p>

        <div className='flex gap-3 flex-col md:flex-row lg:flex-row'>
          <Link to={`/term-of-services`} className='hover:text-darkYellow text-start'>Terms of Service</Link>
          <Link to={`/privacy-policy`} className='hover:text-darkYellow  text-start'>Privacy Policy</Link>
          <Link to={`/cookies-policy`} className='hover:text-darkYellow  text-start'>Cookie Policy</Link>
        </div>

        <ul className=''>
          <li><Link className='hover:text-darkYellow' to="/">Home</Link></li>
          <li><Link className='hover:text-darkYellow' to="/about">About us</Link></li>
          <li><Link className='hover:text-darkYellow' to="/services">Products</Link></li>
          <li><Link className='hover:text-darkYellow' to="/blog">Blog</Link></li>
          <li><Link className='hover:text-darkYellow' to="/contact">Contact us</Link></li>
        </ul>


      </div>
      <div className='text-xs text-gray-400 text-center w-full md:w-[60%] mx-auto my-4'>
        <span className='text-darkYellow font-semibold'>Disclaimer : </span>
        Maestro helps UK based businesses access business finance by working directly with businesses and their appointed representative. We are a broker and do not provide finance or loans ourselves. Quotes are subject to verifying applicant’s circumstances and creditworthiness.
      </div>
      <FixedBottomBar />
    </footer>
  );
}

export default Footer;
