import React from 'react';
import BanerImage from '../../../images/products/product_banner.png';
import FAQItem from '../../FAQs/FAQItem';
import Banner from '../../../components/Banner';
import ScrollToTopOnMount from '../../ScrollToTop';

const faqData = [
    {
        question: 'What is the average duration of a finance term under Property Finance?',
        answer: "At most, the majority of property finance loans take care of the capital requirements of a business for a duration of 18 to 24 months. It all depends on the nature of the project. Smaller projects have short-term loan tenure whilst the larger projects one longer. A minimum of 6-month term is allowed for all development projects.",
    },
    {
        question: 'What are the interest rates?',
        answer: "It depends on the amount you borrow. If you pay a handsome upfront amount as a down payment, you are in for lower repayments rates. Typically, the interest rates are in the range of 4.5% to 5% per annum. ",
    },
    {
        question: 'Can non-UK residents apply for property finance in the UK?',
        answer: `Yes, non-UK residents can apply for property finance, but the criteria and terms may be stricter. Lenders might require a higher deposit and additional documentation to assess the applicant's creditworthiness. `,
    },
    {
        question: 'What are the tax implications of property finance in the UK?',
        answer: "Tax implications can vary based on the type of property and finance. For example, buy-to-let properties are subject to income tax on rental income, and there may be capital gains tax when selling the property. It's advisable to consult a tax professional for specific advice.",
    },
    // {
    //     question: 'Do I need to provide any security or collateral for a Merchant Cash Advance?',
    //     answer: `You don't need to provide any security or collateral to get a Merchant Cash Advance. It's based entirely on your business's future credit and debit card sales.`,
    // },
]

const PropertyFinance = () => {
    return (
        <div>
            <ScrollToTopOnMount/>
            <div className='mt-20'>
               
                <Banner name={'PROPERTY FINANCE'} bannerImg={BanerImage}/>

                {/* details */}

                <div className='my-10 mx-5 sm:mx-20'>
                    <section className="max-w-4xl mx-auto mb-12">
                        <h1 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">WHAT IS PROPERTY FINANCE?</h1>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            Property Finance helps you fund your real estate projects and grow your property ventures. It covers different types of loans, such as bridging loans, mortgages, term loans, and personal loans.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            Although it might seem complicated at first, we'll explain property finance clearly and simply. This way, you can make an informed decision about the best financial option for your property project.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            Whether you need short-term loans for property renovation or long-term mortgages for buying new property, Property Finance offers various options to meet your needs.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            With Property Finance, you can achieve your real estate goals more effectively than with traditional banks.
                        </p>
                    </section>

                    <section className=" mb-12 max-w-4xl mx-auto">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 mb-4">WHICH CONSTRUCTION PROJECTS ARE IDEAL FOR PROPERTY FINANCE?</h2>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            If your business falls into the following categories, you are likely to have a high chance of obtaining loans under the Property Finance scheme:
                        </p>
                        <ul className='text-gray-700 ml-10 mb-6'>
                            <li className='list-disc'>Residential Property Development</li>
                            <li className='list-disc'>Land Acquisition</li>
                            <li className='list-disc'>Property Acquisition</li>
                            <li className='list-disc'>Commercial Property Development</li>
                            <li className='list-disc'>Mezzanine and Development Bridging</li>
                            <li className='list-disc'>Residential & Commercial Property Refurbishments</li>
                            <li className='list-disc'>Property Auctions</li>
                        </ul>
                        {/* <p className="text-base sm:text-lg text-gray-700 mb-6">
                            There are no fixed interest rates or monthly payments. This reduces financial stress by keeping repayments in line with your income. You pay less when you earn less and more when you earn more. It’s beneficial for both you and the lender.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            A Merchant Cash Advance is a quick and flexible way to get funds if you use a card machine (PDQ) or an online payment system for your business.
                        </p> */}

                        {/* <div>
                            <h4 className='text-xl text-gray-700 font-bold my-2'>Minimal Default Risk</h4>
                            <p className='text-base text-gray-700'>Since there are no interest rates or fixed monthly payments with a Merchant Cash Advance, it's less likely you'll default. Repayments are automatically taken as a small percentage of your credit/debit sales revenue.</p>
                            <h4 className='text-xl text-gray-700 font-bold my-2'>Zero Interest</h4>
                            <p className='text-base text-gray-700'>No interest rates at all. It solely relies on the factor rate for repayments. So be sure of no hidden service or charge whatsoever while borrowing from a lender using BCF.</p>
                            <h4 className='text-xl text-gray-700 font-bold my-2'>Quick Cash Access</h4>
                            <p className='text-base text-gray-700'>Lenders can quickly assess the sales history of the borrower through previous credit sales and release funds quickly. BCF sees the highest approval rates across many financial options.</p>
                        </div> */}

                    </section>


                    <section className=" max-w-4xl mx-auto ">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-700 mb-4">WHO CAN APPLY FOR PROPERTY FINANCE?</h2>
                        <div>
                            <p className='text-base sm:text-lg text-gray-700'>Here are the main requirements to qualify for property loans in the UK:</p>
                            <ul className='my-3 text-gray-700'>
                                {/* <h3 className='text-2xl'>Advantages</h3> */}
                                <li className=' list-disc ml-10'>LLPs (Limited Companies, Limited Liability Partnerships), or Registered Sole UK Trading Businesses: Must have a UK-based bank account.</li>
                                <li className=' list-disc ml-10'>Suitable Trading History</li>
                                <h3 className='font-semibold text-lg'>Appropriate Permission Documents</h3>

                                <li className=' list-disc ml-10'>Minimum Develop Asset (Capital)</li>
                                <li className=' list-disc ml-10'>Businesses of any size, large or small, can apply for and get property finance benefits if they adhere to the above standards and certain terms and conditions of lenders.</li>
                                {/* <li className=' list-disc ml-10'>Have more than three months of active business operations.</li> */}
                            </ul>

                            <p className='text-base sm:text-lg text-gray-700'>Checks on the following key factors may also be made for transparency and to ensure secure loan reimbursement:</p>
                            <ul className='text-gray-700 ml-10 mb-6'>
                                <li className='list-disc'>Annual turnover</li>
                                <li className='list-disc'>Historical profit/loss rates</li>
                                <li className='list-disc'>Bank statements</li>
                                <li className='list-disc'>Trading history</li>
                                <li className='list-disc'>Credit score</li>
                            </ul>

                        </div>
                    </section>

                    <section className='max-w-4xl mx-auto '>
                        <h1 className='text-2xl md:text-3xl font-semibold text-gray-800 mb-4'>WHAT ARE THE ADVANTAGES OF PROPERTY FINANCE?</h1>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            You can achieve a wide range of real estate goals using Property Finance. It doesn't restrict developers to specific aspects of development but rather supports the entire construction ecosystem. This includes everything from residential and commercial projects to various other development opportunities.
                        </p>
                        <div>
                            <div>
                                <h3 className='text-xl font-semibold text-gray-700'>Borrow Big, Build Bigger</h3>
                                <p className='text-sm sm:text-base text-gray-700 mb-6'>Property Finance allows borrowers to borrow large sums of money to carry out exhaustive and large-scale projects.</p>
                            </div>
                            <div>
                                <h3 className='text-xl font-semibold text-gray-700'>Better Chances of Funding</h3>
                                <p className='text-sm sm:text-base text-gray-700 mb-6'>It is a lot easier to get funded through Alpha Funding than some traditional banks. The loan amount is sanctioned much faster at Alpha Funding.</p>
                            </div>
                            <div>
                                <h3 className='text-xl font-semibold text-gray-700'>Umbrella Loan</h3>
                                <p className='text-sm sm:text-base text-gray-700 mb-6'>You need not apply for different types of funding for different property objectives. Property finance covers all expenses from A to Z of the property development.</p>
                            </div>

                        </div>
                    </section>
                </div>

                <section className=" max-w-4xl mx-auto mb-4">
                    <h2 className=' text-xl text-gray-700 mx-5'>FREQUENTLY ASKED QUESTIONS</h2>
                    {
                        faqData && faqData?.map((item, index) => (
                            <FAQItem key={index} question={item?.question} answer={item?.answer} />
                        ))
                    }
                    {/* <FAQItem/> */}
                </section>
            </div>
        </div>
    );
}

export default PropertyFinance;
