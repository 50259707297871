import React from 'react';
import BanerImage from '../../../images/products/product_banner.png';
import FAQItem from '../../FAQs/FAQItem';
import Banner from '../../../components/Banner';
import ScrollToTopOnMount from '../../ScrollToTop';

const faqData = [
    {
        question: 'What is the tenure term for Revolving Credit Facility?',
        answer: "The term typically is between 6 months and 2 years. If the borrower wants to renew the agreement, they shall be able to do so after 2 years at the direction of the lender.",
    },
    {
        question: 'What are the interest rates?',
        answer: "Interest rates vary depending on the loan amount and various lenders. The interest rates under RCF are usually higher than other forms of traditional lending facilities. ",
    },
    {
        question: 'Can businesses with bad credit score avail themselves of Revolving Credit Facility?',
        answer: `Some lenders may allow you to do so but only with a personal guarantee agreement. `,
    },
    {
        question: 'Does Revolving Credit Facility require any security/collateral?',
        answer: "No, they do not. However, a personal guarantee is a must-requirement in case of defaulters failing to repay. A personal guarantee legally binds the borrower to pay off debt personally, if the business is unable to do so.",
    },
    // {
    //     question: 'Do I need to provide any security or collateral for a Merchant Cash Advance?',
    //     answer: `You don't need to provide any security or collateral to get a Merchant Cash Advance. It's based entirely on your business's future credit and debit card sales.`,
    // },
]

const RevolvingCreditFacility = () => {
    return (
        <div>
            <ScrollToTopOnMount/>
            <div className='mt-20'>
                <Banner name={'Revolving Credit Facility'} bannerImg={BanerImage}/>

                {/* details */}

                <div className='my-10 mx-5 sm:mx-20'>
                    <section className="max-w-4xl mx-auto mb-12">
                        <h1 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">WHAT IS A REVOLVING CREDIT FACILITY?</h1>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            It is a flexible finance arrangement that allows businesses to withdraw cash as needed. Borrowers in this type of finance agreement repeatedly withdraw and repay capital at agreed periods and interest rates with the lenders.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            RCF is more accessible than similar financial options like overdraft facilities. Revolving Credit Facility is more suited for working capital needs, especially for small and medium-sized businesses (SMEs).
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            SMEs can use RCF funds for various legitimate business purposes, from payrolls to renovations/maintenance. Any cash needs of the business are well taken care of by using the Revolving Credit Facility.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            A Revolving Credit Facility is a dynamic form of business loan, different from static business loans such as overdrafts or secured loans. The funding limit, repayment terms, and conditions are agreed upon between borrower and lender before undertaking the Revolving Credit Facility.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            One of the distinct features of RCF is its convenience of paying interest only on the amount withdrawn from the total loan amount. The cycle is replenished, and the withdrawal amount is reset to the agreed loan amount once you repay your corresponding withdrawals. <br /> <br />

                            Use Revolving Credit to pay interest only on the amount of money used.
                        </p>
                    </section>

                    <section className=" mb-12 max-w-4xl mx-auto">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 mb-4">SALIENT FEATURES OF REVOLVING CREDIT FACILITY</h2>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            Here we highlight and discuss some notable features of the Revolving Credit Facility that make it one of the most popular alternative financing options for businesses.
                        </p>

                        {/* <p className="text-base sm:text-lg text-gray-700 mb-6">
                            There are no fixed interest rates or monthly payments. This reduces financial stress by keeping repayments in line with your income. You pay less when you earn less and more when you earn more. It’s beneficial for both you and the lender.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            A Merchant Cash Advance is a quick and flexible way to get funds if you use a card machine (PDQ) or an online payment system for your business.
                        </p> */}

                        <div>
                            <div>
                                <h4 className='text-xl text-gray-700 font-bold my-2'>Rolling Agreement</h4>
                                <p className='text-base text-gray-700'>RCF does not require renewal or modifications in the agreement every time you withdraw, repay, and re-withdraw. It lays down the clear conditions on the onset of funding which is followed without needing to apply again for the same type of loan.</p>
                            </div>

                            <div>
                                <h4 className='text-xl text-gray-700 font-bold my-2'>Same-day Withdrawals</h4>
                                <p className='text-base text-gray-700'>In many cases, if you prove to be an ideal borrower, some lenders allow you to withdraw cash on the day of your application. RCF proves to be the faster finance instrument than most other finance options.</p>
                            </div>

                            <div>
                                <h4 className='text-xl text-gray-700 font-bold my-2'>Better Cash Flow Management</h4>
                                <p className='text-base text-gray-700'>By availing of RCF, businesses can improve their cash flow management and use the immediately available cash at hand for all business operations. The fact that you can access finance whenever you want keeps the stress of the non-availability of cash at bay.</p>
                            </div>

                            <div>
                                <h4 className='text-xl text-gray-700 font-bold my-2'>Short-term Commitments</h4>
                                <p className='text-base text-gray-700'>Unlike traditional forms of credit facilities, you do not require to be tied to an agreement for long durations with corresponding long interest repayment tenures. RCF is an on-demand facility, meaning that you may discontinue the finance cycle whenever you wish to do so.</p>
                            </div>
                            <div>
                                <h4 className='text-xl text-gray-700 font-bold my-2'>Compounding Relationships</h4>
                                <p className='text-base text-gray-700'>Lenders tend to increase the sanctioned amount and spending limit if the borrower promptly adheres to withdrawal and repayment conditions. This way trust is built with the lender which makes subsequent borrowing easier and faster.</p>
                            </div>

                        </div>

                    </section>


                    <section className=" max-w-4xl mx-auto ">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-700 mb-4">WHO CAN APPLY FOR A REVOLVING CREDIT FACILITY?</h2>
                        <div>
                            <p className='text-base sm:text-lg text-gray-700'>Small and large businesses in the United Kingdom can apply for and receive loan incentives via RCF. However, a crucial qualification for RCF is that the borrower must be a UK-based company with active business operations primarily within the UK.</p>
                            

                            <p className='text-base sm:text-lg text-gray-700'>Ensure that relevant documents such as management accounts, business plans, historical accounts, and asset records are current and prepared for any audit. Financial institutions/lenders may require businesses to undergo various security checks, including standard credit and fraud checks, anti-money laundering, and KYC, to determine eligibility and guarantee repayment for the corresponding loan period.</p>
                            
                        </div>
                    </section>

                    {/* <section className='max-w-4xl mx-auto '>
                        <h1 className='text-2xl md:text-3xl font-semibold text-gray-800 mb-4'>WHAT ARE THE ADVANTAGES OF PROPERTY FINANCE?</h1>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            You can achieve a wide range of real estate goals using Property Finance. It doesn't restrict developers to specific aspects of development but rather supports the entire construction ecosystem. This includes everything from residential and commercial projects to various other development opportunities.
                        </p>
                        <div>
                            <div>
                                <h3 className='text-xl font-semibold text-gray-700'>Borrow Big, Build Bigger</h3>
                                <p className='text-sm sm:text-base text-gray-700 mb-6'>Property Finance allows borrowers to borrow large sums of money to carry out exhaustive and large-scale projects.</p>
                            </div>
                            <div>
                                <h3 className='text-xl font-semibold text-gray-700'>Better Chances of Funding</h3>
                                <p className='text-sm sm:text-base text-gray-700 mb-6'>It is a lot easier to get funded through Alpha Funding than some traditional banks. The loan amount is sanctioned much faster at Alpha Funding.</p>
                            </div>
                            <div>
                                <h3 className='text-xl font-semibold text-gray-700'>Umbrella Loan</h3>
                                <p className='text-sm sm:text-base text-gray-700 mb-6'>You need not apply for different types of funding for different property objectives. Property finance covers all expenses from A to Z of the property development.</p>
                            </div>

                        </div>
                    </section> */}
                </div>

                <section className=" max-w-4xl mx-auto mb-4">
                    <h2 className=' text-xl text-gray-700 mx-5'>FREQUENTLY ASKED QUESTIONS</h2>
                    {
                        faqData && faqData?.map((item, index) => (
                            <FAQItem key={index} question={item?.question} answer={item?.answer} />
                        ))
                    }
                    {/* <FAQItem/> */}
                </section>
            </div>
        </div>
    );
}

export default RevolvingCreditFacility;
