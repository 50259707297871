// src/Service.js
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";

const Service = ({ image, title, description ,path}) => {
    return (
        <motion.div 
        viewport={{ once: true }}
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{
            delay: 0.4,
            y: { type: "spring", stiffness: 60 },
            opacity: { duration: 0.2 },
            ease: "easeIn",
            duration: 1
        }}
        className="bg-white shadow-md rounded-lg p-2 border border-darkYellow sm:p-6 mb-6 w-full flex flex-col xl:flex-row items-center gap-4 ">
            <div className='w-full xl:w-[40%] h-full '>
                <img src={image} alt={title} className="w-full h-full object-cover rounded-lg mb-4" />
            </div>

            <div className='xl:w-[60%] h-full'>
                <h2 className="text-xl font-bold text-darkYellow mb-2">{title}</h2>
                <p className="mb-4 text-gray-600">{description}</p>
                <Link to={path} className='text-mediumYellow flex items-center gap-1 hover:gap-3 duration-500 hover:opacity-70'>
                    Explore <FaArrowRightLong/>
                </Link>
            </div>
        </motion.div>
    );
};

export default Service;
