import React from 'react';
import BanerImage from '../../../images/products/product_banner.png';
import FAQItem from '../../FAQs/FAQItem';
import Banner from '../../../components/Banner';
import ScrollToTopOnMount from '../../ScrollToTop';

const faqData = [
    {
        question: 'Who can benefit from invoice finance?',
        answer: "Any business with unpaid invoices can benefit from invoice finance. It’s especially useful for small to medium-sized businesses needing quick access to cash.",
    },
    {
        question: ' How much can I borrow?',
        answer: "You can borrow based on the value of your unpaid invoices. Lenders typically advance 70-90% of the invoice amount. ",
    },
    {
        question: 'What fees are involved?',
        answer: `Fees include interest charges and service fees. The exact costs vary by lender and agreement. `,
    },
    {
        question: 'How fast can I get the funds?',
        answer: "Funds are usually available within a few days after submitting your invoices.",
    },
    {
        question: 'Is good credit required?',
        answer: `While good credit is beneficial, invoice finance primarily relies on your invoices and your customers’ creditworthiness.`,
    },
    {
        question: 'What happens if my customers don’t pay?',
        answer: `If customers fail to pay, the lender might charge additional fees or take recovery actions, depending on your agreement.`,
    },
    {
        question: 'Can I use invoice finance with other debts?',
        answer: `Yes, you can use invoice finance even if you have other debts.`,
    },
]

const InvoiceFinance = () => {
  return (
    <div>
        <ScrollToTopOnMount/>
    <div className='mt-20'>
        
        <Banner name={'INVOICE FINANCE'} bannerImg={BanerImage}/>

        {/* details */}

        <div className='my-10 mx-5 sm:mx-20'>
            <section className="max-w-4xl mx-auto mb-12">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">WHAT IS INVOICE FINANCE?</h1>
                <p className="text-base sm:text-lg text-gray-700 mb-6">
                Invoice finance is a way for businesses to borrow money based on the amounts due from customers. It helps improve cash flow by releasing funds tied up in unpaid invoices. Here’s how it works:
                </p>

                <ul className='ml-10 text-gray-700'>
                    <li className='list-disc'><span className='font-semibold'>Issue invoices:</span> You provide goods or services to your customers and issue invoices to them.</li>
                    <li className='list-disc'><span className='font-semibold'>Send invoices to the lender:</span> You forward these invoices to an invoice finance provider (also called a lender).</li>
                    <li className='list-disc'><span className='font-semibold'>Get an advance:</span> The lender gives you a percentage of the invoice value upfront, usually around 70-90%.</li>
                    <li className='list-disc'><span className='font-semibold'>Customer pays:</span> Your customer pays the invoice directly to the lender.</li>
                    <li className='list-disc'><span className='font-semibold'>Receive the balance:</span> Once the customer pays the invoice, the lender gives you the remaining balance, minus their fee.</li>
                </ul>

                <p className="text-base sm:text-lg text-gray-700 mb-6">
                There are two main types of invoice finance:
                </p>
                <ul className='text-gray-700 ml-10'>
                    <li className='list-decimal'><span className='font-semibold'>Invoice factoring: </span>The lender manages your sales ledger and collects payments from your customers directly. This is suitable for businesses that don’t have a credit control team.</li>
                    <li className='list-decimal'><span className='font-semibold'>Invoice discounting:  </span>You retain control of your sales ledger and continue to collect payments from your customers. This option is more discreet, as your customers may not know you’re using invoice finance.</li>
                </ul>
                <p className="text-base sm:text-lg text-gray-700 mb-6">
                Invoice finance can help businesses maintain a steady cash flow, meet short-term expenses, and grow without waiting for customers to pay their invoices.
                </p>
               
            </section>


            <section className=" max-w-4xl mx-auto ">
                <h2 className="text-2xl md:text-3xl font-semibold text-gray-700 mb-4">WHO CAN APPLY FOR INVOICE FINANCE?</h2>
                <div>
                    <p className='text-base sm:text-lg text-gray-700'>Invoice finance can be a good option for various types of businesses, especially those that regularly issue invoices and experience delays in receiving payments from customers. Here’s who can typically apply for invoice finance:</p>
                    <ul className='my-3 text-gray-700'>
                        {/* <h3 className='text-2xl'>Advantages</h3> */}
                        <li className=' list-disc ml-10'><span className='font-semibold'>Small to Medium-Sized Businesses (SMEs): </span> Businesses that need to improve cash flow and manage working capital can benefit from invoice finance.</li>
                        <li className=' list-disc ml-10'><span className='font-semibold'>Growing Businesses:</span> Companies expanding and needing immediate cash to cover expenses like payroll, inventory, or other operational costs.</li>
                        <li className='list-disc ml-10'><span className='font-semibold'>Businesses with Long Payment Terms: </span> Those who deal with clients that have extended payment terms (30, 60, 90 days or more).</li>

                        <li className=' list-disc ml-10'><span className='font-semibold'> B2B (Business-to-Business) Companies: </span>Businesses that provide goods or services to other businesses rather than consumers.</li>
                        <li className=' list-disc ml-10'><span className='font-semibold'>Companies with Creditworthy Customers:</span>  Lenders are more likely to approve invoice finance if the invoices are issued to reliable and creditworthy customers.</li>
                        <li className=' list-disc ml-10'><span className='font-semibold'>Service Providers: </span> Professional service firms, such as consulting, legal, and marketing agencies, which often have substantial amounts of unpaid invoices.</li>
                        <li className=' list-disc ml-10'><span className='font-semibold'> Manufacturers and Wholesalers: </span>Businesses that supply products to other companies and face delays in receiving payments.</li>
                        {/* <li className=' list-disc ml-10'>Service Providers: Professional service firms, such as consulting, legal, and marketing agencies, which often have substantial amounts of unpaid invoices.</li> */}
                    </ul>

                    <p className='text-base sm:text-lg text-gray-700'>While most businesses can apply for invoice finance, eligibility criteria can vary between lenders. Generally, businesses should have:</p>
                    <ul className='text-gray-700 ml-10 mb-6'>
                        <li className='list-disc'>A track record of issuing invoices to customers.</li>
                        <li className='list-disc'>Creditworthy customers with a good payment history.</li>
                        <li className='list-disc'>Clear and documented business operations.</li>
                    </ul>

                    <p  className='text-base sm:text-lg text-gray-700 mb-6'>
                    It’s advisable to check specific requirements with potential invoice finance providers, as they may have particular criteria or preferences based on the industry and size of the business.
                    </p>

                </div>
            </section>

            <section className='max-w-4xl mx-auto '>
                <h1 className='text-2xl md:text-3xl font-semibold text-gray-800 mb-4'>WHAT ARE THE ADVANTAGES OF INVOICE FINANCE?</h1>
                <p className="text-base sm:text-lg text-gray-700 mb-6">
                Invoice finance offers several advantages for businesses looking to improve their cash flow and manage their finances more effectively. Here are some key benefits:
                </p>
                <ol className='text-gray-700 ml-10'>
                    <li className='list-disc'><span className='font-semibold'>Improved Cash Flow:</span> Businesses can access cash tied up in unpaid invoices quickly, which helps with day-to-day expenses and operational needs.</li>
                    <li className='list-disc'><span className='font-semibold'>Flexible Financing:</span> The amount of finance available typically grows with the business’s sales, providing a scalable solution that adapts to changing needs.</li>
                    <li className='list-disc'><span className='font-semibold'>No Need for Collateral:</span> Unlike traditional loans that may require assets as collateral, invoice finance is secured against the invoices themselves, often making it easier to qualify.</li>
                    <li className='list-disc'><span className='font-semibold'>Faster Access to Funds:</span> Funds can be accessed within a few days of submitting invoices, rather than waiting weeks or months for customers to pay.</li>
                    <li className='list-disc'><span className='font-semibold'>Focus on Business Growth:</span> With improved cash flow, businesses can invest in growth opportunities, such as expanding operations, purchasing inventory, or hiring new staff.</li>
                    <li className='list-disc'><span className='font-semibold'>Reduced Credit Risk:</span> Some invoice finance providers offer credit control services, including assessing customer creditworthiness and managing collections, reducing the risk of bad debts.</li>
                    <li className='list-disc'><span className='font-semibold'>Flexible Repayment: </span>Payments are tied to the invoices, so businesses repay the finance as customers pay their invoices, rather than having fixed repayment schedules.</li>
                    <li className='list-disc'><span className='font-semibold'>Improved Supplier Relationships:</span> With better cash flow, businesses can pay suppliers on time, potentially securing better terms or discounts.</li>
                    <li className='list-disc'><span className='font-semibold'>No Long-Term Debt:</span> Invoice finance is typically a short-term solution, so it doesn’t add long-term debt to the business’s balance sheet.</li>
                    <li className='list-disc'><span className='font-semibold'>Easier Access for Growing Businesses:</span> It’s often easier to secure invoice finance than traditional loans, especially for businesses with a strong track record of invoicing but limited collateral.</li>
                </ol>

                <p className="text-base sm:text-lg text-gray-700 mb-6">
                Invoice finance helps businesses manage cash flow more effectively, enabling them to focus on growth and operations rather than worrying about delayed payments.
                </p>
            </section>
        </div>

        <section className=" max-w-4xl mx-auto mb-4">
            <h2 className=' text-xl text-gray-700 mx-5'>FREQUENTLY ASKED QUESTIONS</h2>
            {
                faqData && faqData?.map((item, index) => (
                    <FAQItem key={index} question={item?.question} answer={item?.answer} />
                ))
            }
            
        </section>
    </div>
</div>
  );
}

export default InvoiceFinance;
