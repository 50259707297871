import React from 'react';
import '../Home Page/Home.css';
import { motion } from 'framer-motion';
import vector_title from '../../images/title_vector.png'

const data = [
    {
        title: "Loans From £1000 to £10 Million",
        description: "We offer a variety of loans from £1000 to £10 million, and our finance offerings start from as low as £1000 and goes up to as high as £10 million based on the product you select and evaluation of your business requirement. Some of the products that we offer include early repayment options without any extra fees.",
    },
    {
        title: "Multiple Product Options",
        description: "We have a huge range of products which means, no matter the query, you will always find a financial solution to suit the needs of your business. Whether it’s a single solution, or a combination, our experts will get to know your business and deliver a finance solution that fits you perfectly.",
    },
    {
        title: "No Upfront Fees",
        description: "Our revenue is based on only successfully completed deals which is earned from the lender which means that our clients don’t pay us anything in advance and depending on the deal, could pay nothing at all.",
    },
    {
        title: "Dedicated Account Manager",
        description: "We believe in offering customized services and have a dedicated manager catering to every individual account. Our office is open on all weekdays from 9am to 6pm but rest assured, your query will always be dealt with no matter what the time.",
    },
    {
        title: "Services Across UK",
        // description: "We have partnered with multiple lenders, which means most of our services will be available across UK.",
        description: "We have partnered with multiple lenders, ensuring that our services are widely available across the UK. This extensive network of partnerships allows us to offer a broad range of financing options to meet diverse client needs, regardless of their location.",
    },
    {
        title: "Expertise and Experience",
        description: "At Alpha Funding, we pride ourselves on our extensive industry experience and in-depth knowledge of financial services. Our team of seasoned professionals is dedicated to providing personalized solutions tailored to your unique financial situation.",
    },
]

const WhyChooseusCard = ({ title, description }) => {
    return (
        <>


            <motion.div
                viewport={{ once: true }}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                    delay: 0.4,
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1
                }}
                className=' bg-white grid grid-cols-1 border border-darkYellow mx-2 min-h-[20rem] rounded-lg  mb-4 p-3 '>
                <div className=" group bg-yellow-50 rounded-lg p-6 hover:bg-darkYellow cursor-pointer text-white">

                    <h2 className='text-darkYellow group-hover:text-white font-semibold text-2xl '>{title}</h2>
                    <p className="my-4 text-gray-600 group-hover:text-gray-100 text-base text-start">
                        {description}
                    </p>
                </div>
            </motion.div>
        </>
    )
}

const WhyChooseus = () => {
    return (
        <div>
            <div className="section-container-img px-[5rem]">
               

                <div className='mx-[1rem] sm:mx-[2rem] md:mx-[3rem] lg:mx-[5rem]'>
                    <div className=" flex mx-auto w-fit mb-6 ">
                        <img src={vector_title} alt='Title vector' className="mr-4 sm:w-[2rem]" />
                        <p className="text-xl lg:text-2xl font-semibold text-darkYellow">Why Choose Us</p>
                    </div>
                    {/* boxes */}
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
                        {
                            data && data?.map((item, index) => {
                                return (
                                    <WhyChooseusCard key={index} title={item?.title} description={item?.description} />
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}

export default WhyChooseus;
