import React from 'react';
import BanerImage from '../../../images/products/product_banner.png';
import FAQItem from '../../FAQs/FAQItem';
import Banner from '../../../components/Banner';
import ScrollToTopOnMount from '../../ScrollToTop';

const faqData = [
    {
        question: 'How long can repayments of asset finance go on?',
        answer: "Over a period of one year to seven year, one may avail repayments under the asset finance option. ",
    },
    {
        question: 'What industries that can take advantage of Asset Finance?',
        answer: "Asset Finance caters to all types and sizes of industries in the UK. You can take advantage of asset finance for hire purchases, finance leases, asset refinance, construction, gym equipment and many more.  ",
    },
    {
        question: 'What if I have a bad credit score?',
        answer: `A good credit score is a criterion for funding via Asset Finance. This is because it reduces the lenders' risk from the habitual defaulters. Some lenders, however, may allow you to borrow asset finance even with a bad credit score, but mostly at the cost of an increased factor rate. `,
    },
    {
        question: 'Do I have to provide any security or collateral to avail Asset Finance?',
        answer: "Asset Finance does require you to furnish a security or collateral tied to land or property to avail of its benefits. ",
    },
    
]

const AssetFinance = () => {
  return (
    <div>
        <ScrollToTopOnMount/>
            <div className='mt-20'>
               
                <Banner name={'Asset Finance'} bannerImg={BanerImage}/>

                {/* details */}

                <div className='my-10 mx-5 sm:mx-20'>
                    <section className="max-w-4xl mx-auto mb-12">
                        <h1 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">WHAT IS ASSET FINANCE?</h1>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                        Asset finance helps businesses get the money they need to buy things like equipment, machinery, vehicles, or real estate. Instead of buying the asset outright, the business borrows money to pay for it and then repays the loan with interest over time.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                        In asset finance, the asset being bought serves as collateral for the loan. If the business can't repay the loan, the lender can take the asset to recover their losses. This type of financing allows businesses to get the assets they need without making a large upfront payment.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                        There are different types of asset finance, such as lease financing, equipment financing, and commercial real estate financing. With lease financing, a business can rent equipment or machinery and may have the option to buy it at the end of the lease term. Equipment financing helps businesses buy equipment by using the equipment as collateral. Commercial real estate financing helps businesses buy or refinance real estate property.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                        Asset finance can be a cost-effective way for businesses to get the assets they need to operate or grow. It also offers flexibility, allowing businesses to tailor the financing to their needs. Additionally, it helps businesses conserve cash and maintain their working capital, as they only pay for the asset as they use it.
                        </p>
                    </section>

                    <section className=" mb-12 max-w-4xl mx-auto">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 mb-4">HOW DOES ASSET FINANCE WORK?</h2>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                        Asset finance is a type of loan that helps businesses buy or improve assets like equipment, vehicles, or real estate by using the assets as collateral. Here’s how it works:
                        </p>
                        <ul className='text-gray-700 ml-10 mb-6'>
                            <li className='list-decimal'><span className='font-semibold'>Evaluation of assets:</span> The lender assesses the value and condition of the assets the business wants to use as collateral.</li>
                            <li className='list-decimal'><span className='font-semibold'>Agreement and repayment terms:</span>If the assets meet the lender’s criteria, both parties agree on the loan amount, interest rate, and repayment terms.</li>

                            <li className='list-decimal'><span className='font-semibold'>Loan disbursement:</span> The lender gives the business the loan amount, which can be used to purchase or upgrade the assets.</li>
                            <li className='list-decimal'><span className='font-semibold'>Repayment of loan:</span> The business repays the loan with interest over a set period, usually between one to five years.</li>
                            <li className='list-decimal'><span className='font-semibold'>Lender’s ownership of assets:</span> If the business can’t repay the loan, the lender takes ownership of the assets.</li>

                        </ul>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                        Asset finance is a flexible way for businesses to get the funds they need to buy or improve assets without having the money upfront. It allows businesses to use the assets they buy as security for the loan, making it easier to get financing and reducing the risk for the lender. Asset finance can also be used to pay off loans for already-owned equipment, freeing up cash flow and potentially lowering the overall cost of financing.
                        </p>
                        {/* <p className="text-base sm:text-lg text-gray-700 mb-6">
                            A Merchant Cash Advance is a quick and flexible way to get funds if you use a card machine (PDQ) or an online payment system for your business.
                        </p> */}

                        {/* <div>
                            <h4 className='text-xl text-gray-700 font-bold my-2'>Minimal Default Risk</h4>
                            <p className='text-base text-gray-700'>Since there are no interest rates or fixed monthly payments with a Merchant Cash Advance, it's less likely you'll default. Repayments are automatically taken as a small percentage of your credit/debit sales revenue.</p>
                            <h4 className='text-xl text-gray-700 font-bold my-2'>Zero Interest</h4>
                            <p className='text-base text-gray-700'>No interest rates at all. It solely relies on the factor rate for repayments. So be sure of no hidden service or charge whatsoever while borrowing from a lender using BCF.</p>
                            <h4 className='text-xl text-gray-700 font-bold my-2'>Quick Cash Access</h4>
                            <p className='text-base text-gray-700'>Lenders can quickly assess the sales history of the borrower through previous credit sales and release funds quickly. BCF sees the highest approval rates across many financial options.</p>
                        </div> */}

                    </section>

                    <section className=" mb-12 max-w-4xl mx-auto">
                        <h2 className='text-2xl md:text-3xl font-semibold text-gray-700 mb-4'>WHAT ARE THE DIFFERENT TYPES OF ASSET FINANCE?</h2>
                        <p className='text-base sm:text-lg text-gray-700'>There are several types of asset finance, each designed to help businesses buy or use different kinds of assets. Here are the main types:</p>
                        <ul className='ml-10 text-gray-700'>
                            <li className='list-decimal'><span className='font-semibold'>Equipment financing:</span>Businesses can buy new or used equipment using the equipment itself as collateral for the loan.</li>
                            <li className='list-decimal'><span className='font-semibold'>Vehicle financing: </span>Businesses can buy vehicles like cars, trucks, or vans using the vehicles as collateral for the loan.</li>
                            <li className='list-decimal'><span className='font-semibold'>Real estate financing:</span>Businesses can buy or refinance real estate using the property as collateral for the loan.</li>
                            <li className='list-decimal'><span className='font-semibold'>Inventory financing: </span>Businesses can finance their inventory by using the inventory as collateral for the loan.</li>
                            <li className='list-decimal'><span className='font-semibold'>Leasing: </span>Businesses can use equipment, vehicles, or other assets without owning them. They make regular payments to the lender, who keeps ownership of the asset.</li>
                            <li className='list-decimal'><span className='font-semibold'>Hire purchase:</span>Businesses can buy assets by making regular payments over time. At the end of the agreement, they can own the asset by making a final payment.</li>
                            <li className='list-decimal'><span className='font-semibold'>Factoring: </span>Businesses can get an advance on their accounts receivable. The lender gives a percentage of the value of the accounts receivable, and the business repays the loan with interest when the accounts are paid.</li>
                        </ul>

                        <p className='text-base sm:text-lg text-gray-700'>Each type of asset finance has unique features and benefits. The best option depends on the business's specific needs and situation. It’s important for businesses to carefully consider their choices and select the type of asset finance that fits them best.</p>
                    </section>


                    <section className=" max-w-4xl mx-auto ">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-700 mb-4">WHO IS ELIGIBLE FOR ASSET FINANCE?</h2>
                        <div>
                            <p className='text-base sm:text-lg text-gray-700'>Here are the main requirements to get Asset Finance in the UK:</p>
                            <ul className='my-3 text-gray-700'>
                                {/* <h3 className='text-2xl'>Advantages</h3> */}
                                <li className=' list-disc ml-10'><span className='font-semibold'>UK-based business:</span>  Your business must be trading in the UK and have a UK bank account.</li>
                                <li className=' list-disc ml-10'><span className='font-semibold'>Minimum turnover:</span>  Your business needs to have a turnover of at least £10,000.</li>
                                <li className='list-disc ml-10'> <span className='font-semibold'>Business type:</span>  You must be a limited company, a limited liability partnership (LLP), or a registered sole trader based in the UK.</li>
                                <li className=' list-disc ml-10'><span className='font-semibold'>Active operations:</span>  You need to provide documents showing your business is actively operating.</li>
                            </ul>

                            <p className='text-base sm:text-lg text-gray-700'>Small and medium-sized businesses that meet these standards and follow specific lenders' terms and conditions can apply for Asset Finance.</p>
                            

                        </div>
                    </section>

                    <section className='max-w-4xl mx-auto '>
                        <h1 className='text-2xl md:text-3xl font-semibold text-gray-800 mb-4'>KEY BENEFITS OF ASSET FINANCE</h1>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                        Asset finance has many advantages over traditional loans. Here are the benefits:
                        </p>

                        <ul className='text-gray-700 ml-10'>
                            <li className='list-decimal'><span className='font-semibold'>Access to funds:</span>  Businesses can buy or upgrade necessary assets even if they don't have enough money right now.</li>
                            <li className='list-decimal'><span className='font-semibold'>Reduced risk:</span>  Using assets as collateral makes it easier for businesses to get financing and lowers the risk for lenders.</li>
                            <li className='list-decimal'><span className='font-semibold'> Flexible repayment terms:</span> Businesses can choose a repayment schedule that fits their budget and cash flow.</li>
                            <li className='list-decimal'><span className='font-semibold'>Tax benefits:</span>  There may be tax deductions for interest paid on the loan.</li>
                            <li className='list-decimal'><span className='font-semibold'> Improved cash flow:</span> Financing assets instead of paying upfront allows businesses to save cash for other needs.</li>
                            <li className='list-decimal'><span className='font-semibold'>Upgraded assets:</span>  Businesses can buy or upgrade equipment and property, improving efficiency and competitiveness.</li>
                            <li className='list-decimal'><span className='font-semibold'>Opportunity to own assets:</span>  With options like hire purchase or leasing, businesses can eventually own the assets they use.</li>
                        </ul>
                        <p className='text-base sm:text-lg text-gray-700 mb-6'>
                            Asset finance is a flexible way for businesses to get the money they need to grow and operate while keeping cash flow healthy and lowering risk. It’s important for businesses to carefully consider their financing options and choose the one that best fits their needs.
                        </p>
                        <div>
                            <div>
                                <h3 className='text-xl font-semibold text-gray-700'>Minimal Default Risk</h3>
                                <p className='text-sm sm:text-base text-gray-700 mb-6'>With minimal interest rates, the chances of defaulting on the payback are very less since repayments on agreed interest rates are done automatically on the earned revenue.</p>
                            </div>
                            <div>
                                <h3 className='text-xl font-semibold text-gray-700'>Nominal Interest Rates</h3>
                                <p className='text-sm sm:text-base text-gray-700 mb-6'>Bases on the loan amount, certain interest rates shall be applicable. Repayments can be separated over several months or years for hassle-free returns. Other than interest rates, no hidden service or charge whatsoever while borrowing from a lender through asset finance.</p>
                            </div>
                            <div>
                                <h3 className='text-xl font-semibold text-gray-700'>Quick Cash Access</h3>
                                <p className='text-sm sm:text-base text-gray-700 mb-6'>Lenders can quickly assess the turnover of the borrower through previous transaction history and release funds quickly. Asset Finance sees the highest number of applications across many financial options.</p>
                            </div>

                        </div>
                    </section>
                </div>

                <section className=" max-w-4xl mx-auto mb-4">
                    <h2 className=' text-xl text-gray-700 mx-5'>FREQUENTLY ASKED QUESTIONS</h2>
                    {
                        faqData && faqData?.map((item, index) => (
                            <FAQItem key={index} question={item?.question} answer={item?.answer} />
                        ))
                    }
                    {/* <FAQItem/> */}
                </section>
            </div>
        </div>
  );
}

export default AssetFinance;
