// src/components/FAQItem.js
import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`my-3 border-gray-200 mx-4 bg-yellow-50  rounded-lg duration-500 overflow-hidden cursor-pointer`}>
      <div
        className="flex justify-between items-center w-full py-2 px-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-base font-medium text-gray-900 w-[80%] ">{question}</span>
        {isOpen ? 
        <FaMinus className=" bg-mediumYellow duration-500 text-white  p-2 w-[30px] h-[30px]  rounded-full cursor-pointer" /> 
        : 
        <FaPlus className=" bg-darkYellow text-white p-2 w-[30px]  h-[30px]  rounded-full cursor-pointer" />}
      </div>

      {/* <div className=' bg-white'>
        <p className='text-xs'>{answer}</p>
      </div> */}
      {isOpen && (
        <div className="p-4 bg-gray-50 text-sm text-gray-700">
          {answer}
        </div>
      )}
    </div>
  );
};

export default FAQItem;
