import React from 'react';

const Cookies_Policy = () => {
    return (
        <div className="my-[8rem] mx-[1rem] sm:mx-[2rem] md:mx-[5rem]">
            <h1 className='text-3xl text-darkYellow text-center font-bold'>Cookie Policy</h1>
            <p className='text-sm font-semibold text-gray-700'>Last updated: April 07, 2022</p>

            <section className='text-xl text-slate-800 mt-4 mx-[1rem] md:mx-[3rem] my-[1.5rem]'>
                This policy (“Cookie Policy”) forms part of the Maestro
                Finance Privacy Policy (“Privacy Policy”). Capitalized terms not
                otherwise defined in this Cookie Policy have the meaning given to
                them in the Privacy Policy.
            </section>

            <section className='mx-[1rem] md:mx-[3rem] my-[1.5rem]'>
                <h2 className='text-2xl font-bold my-5'>What are cookies?</h2>
                <p className='text-xl text-slate-800 my-4'>
                    Cookies are small pieces of data stored on your browser,
                    computer or other device when you visit websites, including our
                    website (“our Website”). Cookies usually contain anonymous
                    information, which is accessible by the website which set it, on
                    each later visit to that website or sometimes when you visit other
                    websites.
                </p>

                <p className='text-xl text-slate-800 my-4'>
                    Please note that we can also collect information about your usage
                    of our Website from data contained in “log files”. Log files are not
                    cookies; they do not contain any personal data; and they are not
                    used to identify your personal use of our Website. When you
                    request any web page from our Website, web servers
                    automatically obtain your domain name and internet protocol
                    (“IP”) address, but they reveal nothing personal about you and
                    that data is only used to examine Website traffic in aggregate, to
                    investigate abuse of our Website and its users, and/or to cooperate with law enforcement. Such data is not disseminated to
                    third parties, except in aggregate.
                </p>

            </section>

            <section className='mx-[1rem] md:mx-[3rem] my-[1.5rem]'>
                <h2 className='text-2xl font-bold my-5'>How do we use cookies?</h2>

                <p className=' text-xl text-slate-800 my-4'>
                    We use cookies to store the following data:
                    <ul className='mx-12'>
                        <li className='list-disc text-slate-800 my-1'>How you use our Website</li>
                        <li className='list-disc text-slate-800 my-1'>Identifiers such as your device and location</li>
                        <li className='list-disc text-slate-800 my-1'>Your preferences for our Website and services</li>
                    </ul>
                    Cookies cannot harm your computer or other device.
                </p>


            </section>

            <section className='mx-[1rem] md:mx-[3rem] my-[1.5rem]'>
                <h2 className='text-2xl font-bold my-5'>What cookies do we use?</h2>
                <p className='text-xl text-slate-800 my-4'>
                    There are four different types of cookies, which we explain here,
                    and then we set out the cookies we uses in the table below.
                </p>

                <ul className='mx-8 my-4'>
                    <li className='text-xl text-slate-800 list-disc my-2'>
                        <b>Necessary cookies:</b> Those required for the operation of our
                        Website, which do not gather information about you that
                        could be used for marketing or remembering where you
                        have been on the internet.
                    </li>
                    <li className='text-xl text-slate-800 list-disc my-2'>
                        <b>Analytical/performance cookies:</b>  These allow us to collect
                        information about how you use our Website, such as, how
                        you move around our Website and if you experience any
                        errors. These cookies do not collect personal data. The
                        information collected is anonymous and is only used to help
                        us improve the way our Website works, understand what
                        interests our users generally and measure how effective our
                        advertising is. Some of the performance cookies we use are
                        issued as part of services provided by third parties, like
                        Google Analytics.
                    </li>
                    <li className='text-xl text-slate-800 list-disc my-2'>
                        <b>Functionality cookies:</b> These are used to provide services
                        or to recognise you when you return to our Website. These
                        would enable us to personalise our content for you, greet
                        you by name and remember your preferences and improve
                        your visit.
                    </li>

                    <li className='text-xl text-slate-800 list-disc my-2'>
                        <b>Targeting cookies:</b> These record your visit to a certain
                        website, the pages you have visited and the links you have
                        followed. They are linked to services provided by third
                        parties, such as “Like” and “Share” buttons, in return for
                        recognising that you have visited the relevant website. The
                        third party may subsequently use information about your visit
                        to target advertising to you on other websites and present
                        you with advertisements that you may be interested in.
                    </li>
                </ul>
            </section>

            <section className='mx-[1rem] md:mx-[3rem] my-[1.5rem]'>
                <h2 className='text-2xl font-bold my-5'>How do I manage my cookie settings?</h2>

                <p className='text-xl text-slate-800 my-4'>
                    Please note that configuring your computer and/or mobile browser
                    to reject ‘necessary’, ‘performance’ or ‘functional’ cookies may
                    severely impact your experience on our Website and some parts
                    of our Website will not function at all.

                </p>
                <p className='text-xl text-slate-800 my-4'>
                    All browsers provide tools that allow you to control how you
                    handle cookies: accept, reject or delete them. These settings are
                    normally accessed via the ‘settings’, ‘preferences’ or ‘options’
                    menu of the browser you are using, but you could also look for a
                    ‘help’ function or contact the browser provider.
                </p>

                <p className='text-xl text-slate-800 my-4'>
                    You should check the privacy policy any third-party service(s) you
                    may use that set Targeting cookies on your browser or device.
                    You may also be able to use tools provided by those third parties
                    (and the third parties mentioned in the table above) to prevent
                    their Targeting cookies being set.
                </p>
            </section>
        </div>
    );
}

export default Cookies_Policy;
