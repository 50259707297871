import React, { useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from 'framer-motion';
// import './Testimonial.css';
import TestimonialCard from './TestimonialCard';
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import user from '../../images/user.png';

//images
import titleVector from '../../images/title_vector.png';

const testimonials = [
    {
        name: "Brian",
        Rating:5,
        role: "",
        testimonial: "I was pleasantly surprised by how fast the approval process was with Maestro Finance. After submitting my application, I received approval within a few days, and the funds were disbursed shortly after. This swift process helped my business immensely.",
        image: user  // Replace with actual image path
    },
    {
        name: "Hannah",
        Rating:5,
        role: "",
        testimonial: "The application process was straightforward and quick. The online application form was user-friendly, and the instructions were easy to follow. I appreciate James in helping me with the paperwork.",
        image: user // Replace with actual image path
    },
    {
        name: "Kevin Harriman",
        Rating:4,
        role: "",
        testimonial: "The repayment terms offered were very flexible, allowing me to choose a schedule that suited my cash flow. They also offer the option for early repayment without penalties, which is a great advantage.",
        image: user  // Replace with actual image path
    },
    // Add more testimonials as needed
];

const TestimonialSlider = () => {
    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };



    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="w-full px-[1rem] lg:px-[4rem] py-10 ">
            <motion.div
                // viewport={{ once: true }}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                    delay: 0.4,
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1
                }}
                className='flex gap-3 w-fit mx-auto my-4 '>
                <img src={titleVector} alt="title" />
                <h4 className='text-2xl font-semibold opacity-95 text-darkYellow '> Testimonials</h4>
            </motion.div>
            <motion.h2
                // viewport={{ once: true }}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                    delay: 0.4,
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1
                }}
                className=" text-2xl md:text-4xl lg:w-[50%] mx-auto font-bold text-center text-slate-800 mb-2">
                {/* Success Stories Shared by Our Customers */}
                What People Say About Us
            </motion.h2>
            <motion.p
                // viewport={{ once: true }}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                    delay: 0.4,
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1
                }}
                className="text-center lg:w-[50%] mx-auto mb-2 text-gray-500">
                Feel free to customize the text with actual client testimonials, ensuring you have their permission to use their names and occupations.
            </motion.p>

            <Slider {...settings}
                ref={slider => {
                    sliderRef = slider;
                }}
                className=' mb-6 pt-12 relative '>
                {testimonials.map((testimonial, index) => (
                    //   <div key={index} className="p-4">
                    //     <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center">
                    //       <div className="flex items-center mb-4">
                    //         <img src={testimonial.image} alt={testimonial.name} className="w-16 h-16 rounded-full mr-4"/>
                    //         <div>
                    //           <h3 className="text-xl font-semibold">{testimonial.name}</h3>
                    //           <p className="text-sm text-gray-500">{testimonial.role}</p>
                    //         </div>
                    //       </div>
                    //       <p className="text-center text-gray-600">{testimonial.testimonial}</p>
                    //     </div>
                    //   </div>
                    <TestimonialCard testimonial={testimonial} key={index} />
                ))}
            </Slider>
            <motion.div
                viewport={{ once: true }}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                    delay: 0.4,
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1
                }}
                style={{ textAlign: "center" }}
                className='flex justify-center gap-3 max-auto'>
                <button
                    className=" bg-white p-0 rounded-full flex justify-center items-center text-darkYellow hover:bg-darkYellow duration-300 hover:text-white"
                    style={{ border: '1px solid #ff9408', width: "40px", height: "40px" }}
                    onClick={previous}>
                    <TfiAngleLeft className='text-base' />
                </button>
                <button
                    className=" bg-white p-0 rounded-full flex justify-center items-center text-darkYellow hover:bg-darkYellow duration-300 hover:text-white"
                    style={{ border: '1px solid #ff9408', width: "40px", height: "40px" }}
                    onClick={next}>
                    <TfiAngleRight className='text-base' />
                </button>
            </motion.div>


            <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
                delay: 0.4,
                y: { type: "spring", stiffness: 60 },
                opacity: { duration: 0.2 },
                ease: "easeIn",
                duration: 1
            }}
             className='w-fit mx-auto px-4 py-4 my-4'>
                <a 
                href="https://uk.trustpilot.com/review/maestrofinance.co.uk"
                target='_blank'
                rel="noreferrer"
                className='sm:ml-5 px-6 lg:px-8 py-3 font-bold rounded-full mb-4 lg:mb-0 lg:mr-4 text-white hover:text-darkYellow bg-darkYellow hover:bg-white transition-all duration-200 ease-in border border-darkYellow'
                >
                    See More
                </a>
            </motion.div>
        </div>
    );
};

export default TestimonialSlider;
