import React from 'react';
import BanerImage from '../../../images/products/product_banner.png';
import FAQItem from '../../FAQs/FAQItem';
import Banner from '../../../components/Banner';
import ScrollToTopOnMount from '../../ScrollToTop';

const faqData = [
    {
        question: 'What is a factor rate in Business Cash Advanced?',
        answer: "The fee for a Business Cash Advance is called the finance rate. Unlike interest rates, factor rates are fixed amounts per pound borrowed. They usually range from 1.2 to 1.5. For example, if a business borrows £20,000 with a factor rate of 1.2, they would repay a total of £24,000.",
    },
    {
        question: 'Are Business Cash Advanced and Merchant Cash Advanced the same?',
        answer: "Yes, they both mean the same thing and can be used interchangeably. ",
    },
    {
        question: 'Which industries can benefit from a Merchant Cash Advance??',
        answer: `Business Cash Advance is available for all types and sizes of businesses in the UK. However, it's best suited for businesses that process a lot of credit and debit card transactions. These can include restaurants, bars, online shops, hotels, auto repair shops, and similar businesses. `,
    },
    {
        question: 'What if I have bad credit score?',
        answer: "A good credit score is important for getting a Merchant Cash Advance because it lowers the risk for lenders. Some lenders might still offer you a cash advance with a bad credit score, but they usually charge a higher factor rate.",
    },
    {
        question: 'Do I need to provide any security or collateral for a Merchant Cash Advance?',
        answer: `You don't need to provide any security or collateral to get a Merchant Cash Advance. It's based entirely on your business's future credit and debit card sales.`,
    },
]

const BusinessCashAdvance = () => {
    return (
        <div>
            <ScrollToTopOnMount/>
            <div className='mt-20'>
               
                <Banner name={'Business Cash Advance'} bannerImg={BanerImage}/>

                {/* details */}

                <div className='my-10 mx-5 sm:mx-20'>
                    <section className="max-w-4xl mx-auto mb-12">
                        <h1 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">WHAT IS BUSINESS CASH ADVANCE?</h1>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            A Business Cash Advance, also known as a Merchant Cash Advance, allows small and medium-sized businesses (SMEs) to quickly and easily get funding up to £200,000. This funding option is different from traditional loans because no interest is charged on the borrowed amount. Instead, a factor rate (explained below) is applied to the total amount.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            One distinct feature of a Business Cash Advance is that it allows businesses to repay with the revenue they generate through their credit and debit card sales. Lenders charge a fixed, small percentage (factor rate) on the revenue. This directs the repayment rates, so you only repay from your earned income.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            There are no fixed interest rates or set monthly payments with the Merchant Cash Advance Facility. It greatly reduces financial stress on the borrower by keeping the repayment rates predictable and in line with growth. You pay less when you earn less and more when you earn more, making it a win-win situation for both borrowers and lenders.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            A Merchant Cash Advance is a quick and flexible option to borrow funds for your business if you handle payments through a card machine (PDQ) and/or an internet payment system.
                        </p>
                    </section>

                    <section className=" mb-12 max-w-4xl mx-auto">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 mb-4">KEY BENEFITS OF BUSINESS CASH ADVANCE</h2>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            A Business Cash Advance has a unique feature: you repay it with the revenue from your credit and debit card sales. Instead of fixed interest, lenders charge a small percentage of your revenue. This means you only repay from what you earn.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            There are no fixed interest rates or monthly payments. This reduces financial stress by keeping repayments in line with your income. You pay less when you earn less and more when you earn more. It’s beneficial for both you and the lender.
                        </p>
                        <p className="text-base sm:text-lg text-gray-700 mb-6">
                            A Merchant Cash Advance is a quick and flexible way to get funds if you use a card machine (PDQ) or an online payment system for your business.
                        </p>

                        <div>
                            <h4 className='text-xl text-gray-700 font-bold my-2'>Minimal Default Risk</h4>
                            <p className='text-base text-gray-700'>Since there are no interest rates or fixed monthly payments with a Merchant Cash Advance, it's less likely you'll default. Repayments are automatically taken as a small percentage of your credit/debit sales revenue.</p>
                            <h4 className='text-xl text-gray-700 font-bold my-2'>Zero Interest</h4>
                            <p className='text-base text-gray-700'>No interest rates at all. It solely relies on the factor rate for repayments. So be sure of no hidden service or charge whatsoever while borrowing from a lender using BCF.</p>
                            <h4 className='text-xl text-gray-700 font-bold my-2'>Quick Cash Access</h4>
                            <p className='text-base text-gray-700'>Lenders can quickly assess the sales history of the borrower through previous credit sales and release funds quickly. BCF sees the highest approval rates across many financial options.</p>
                        </div>

                    </section>

                   
                    <section className=" max-w-4xl mx-auto py-10">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-700 mb-4">WHO IS ELIGIBLE FOR BUSINESS CASH ADVANCE?</h2>
                        <div>
                            <p className='text-base sm:text-lg text-gray-700'>Here are the key requirements to get Business Cash Finance in the UK:</p>
                            <ul className='my-3 text-gray-700'>
                                {/* <h3 className='text-2xl'>Advantages</h3> */}
                                <li className=' list-disc ml-10'>Accept payments using credit and debit cards (i.e., have a business account).</li>
                                <li className=' list-disc ml-10'>Be a UK trading business with a UK-based bank account.</li>
                                <li className=' list-disc ml-10'>Process at least £2,500 in payments monthly.</li>
                                <li className=' list-disc ml-10'>Be an LLP (limited company, limited liability partnership) or a registered sole UK-based trader.</li>
                                <li className=' list-disc ml-10'>Have more than three months of active business operations.</li>
                            </ul>
                            <p className='text-base sm:text-lg text-gray-700'>Small and medium-sized businesses can apply for Merchant Cash Finance if they meet these requirements and follow the terms and conditions of the lenders.</p>

                        </div>
                    </section>
                </div>

                <section className=" max-w-4xl mx-auto mb-4">
                    <h2 className=' text-xl text-gray-700 mx-5'>FREQUENTLY ASKED QUESTIONS</h2>
                    {
                        faqData && faqData?.map((item, index) => (
                            <FAQItem key={index} question={item?.question} answer={item?.answer} />
                        ))
                    }
                    {/* <FAQItem/> */}
                </section>
            </div>
        </div>
    );
}

export default BusinessCashAdvance;
