import React from 'react';
import { motion } from 'framer-motion';
// import howitworkimg from '../../images/how_works-2.jpg';
// import howitworkimg from '../../images/Home.png';
import howitworkimg from '../../images/products/about us.jpg';
// import titleVector from '../../images/title_vector.png'



const Step = ({ number, title, description }) => {
    return (
        <motion.div
            viewport={{ once: true }}
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
                delay: 0.4,
                y: { type: "spring", stiffness: 60 },
                opacity: { duration: 0.2 },
                ease: "easeIn",
                duration: 1
            }}
            className="flex items-start border-b border-dashed border-gray-400 pb-6 space-x-4">
            <div className="flex-shrink-0 h-12 w-12 flex items-center justify-center bg-yellow-200 rounded-full text-yellow-800 text-lg font-bold">
                {number}
            </div>
            <div>
                <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
                <p className="text-gray-600 text-sm">{description}</p>
            </div>
        </motion.div>
    );
};

const HowItWorks = () => {
    return (
        <div className="bg-white grid grid-cols-1 lg:grid-cols-2 gap-10 md:mx-[3rem] p-10 py-[5rem] ">
            <div className=' flex justify-center items-center'>
                <img src={howitworkimg} alt="How it works images" className='' />
            </div>
            <div className="max-w-4xl mx-auto ">
                {/* <motion.div
                    viewport={{ once: true }}
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                        delay: 0.4,
                        y: { type: "spring", stiffness: 60 },
                        opacity: { duration: 0.2 },
                        ease: "easeIn",
                        duration: 1
                    }}
                    className='flex gap-4 my-2 md:my-4'>
                    <img src={titleVector} alt="Title vector" />
                    <h4 className='font-semibold text-btnGreen text-2xl'>How It Works</h4>
                </motion.div> */}

                <motion.h2
                    viewport={{ once: true }}
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                        delay: 0.4,
                        y: { type: "spring", stiffness: 60 },
                        opacity: { duration: 0.2 },
                        ease: "easeIn",
                        duration: 1
                    }}
                    className="text-3xl font-bold text-gray-800 mb-4">
                    Apply for a loan in minutes
                </motion.h2>

                <motion.p
                    viewport={{ once: true }}
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                        delay: 0.4,
                        y: { type: "spring", stiffness: 60 },
                        opacity: { duration: 0.2 },
                        ease: "easeIn",
                        duration: 1
                    }}
                    className="text-gray-600 mb-8">
                    {/* The goal is to provide a clear and concise overview of how your platform works and guide users through the loan application journey. */}
                    We will match you with the right funding partners. Finance is just some click away.
                </motion.p>
                <div className="space-y-8">
                    <Step
                        number="1"
                        // title="Search Your Loan Request"
                        title="Check Eligibility"
                        // description="Begin by filling out our simple loan request form. Provide us with some basic information."
                        description="To know, without credit score of course, if you can avail loan."
                    />
                    <Step
                        number="2"
                        // title="Compare Loan Options"
                        title="Fill A Simple Form"
                        // description="Picking a product refers to the process of selecting a specific product from a range of options."
                        description="Tell us more about you and your capital needs."
                    />
                    <Step
                        number="3"
                        // title="Select Your Preferred Loan"
                        title="Hear from Us"
                        // description="Product packaging refers to the materials and design used to protect and present a product."
                        description="We’ll get back to you with a personalized, no-obligation quote."
                    />
                    <Step
                        number="4"
                        // title="Complete the Application"
                        title="Fund Transfer"
                        // description="Follow the lender’s instructions to complete the loan application. You may need to provide additional documentation."
                        description="If approved, money is deposited directly into your account."
                    />
                </div>
            </div>
        </div>
    );
};



export default HowItWorks;
