import React from 'react'
import './Home.css'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FcCustomerSupport } from "react-icons/fc";
import { GrCalculator } from "react-icons/gr";
import { FaHouseDamage } from "react-icons/fa";
import { IoDocumentsOutline } from "react-icons/io5";
import { GoArrowUpRight } from "react-icons/go";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// images
import title_vector from '../../images/title_vector.png'
import UnsecuredBusinessFinance from '../../images/services/business-unsecured.svg';
import PropertyFinance from '../../images/services/property_finance.svg';
import AssetFinance from '../../images/services/asset_finance.svg';
import BusinessCashAdvance from '../../images/services/Business_cash.svg';
import InvoiceFinance from '../../images/services/invoice_finance.svg';
import RevolvingCreditFacility from '../../images/services/revolving_credit_facility.svg';
import Homebaner from '../../images/services/19730.png';
//components
import TestimonialSlider from '../../components/Home/Testimonial';
import HowItWorks from '../../components/Home/HowItWorks';
// import PartnerLogo from '../../components/Home/PartnerLogo';
import LoanCalculatorComponent from '../About/LoanCalculator';
import WhyChooseus from '../About/WhyChooseus';
import ScrollToTopOnMount from '../ScrollToTop';


const Home = () => {



    const data = [
        {
            icon: <GrCalculator />, 
            img: UnsecuredBusinessFinance,
            title: "Unsecured Business Finance",
            description: "Simple, fast and low-cost loans for SMEs looking to finance their businesses without putting their assets at risk. Opt for greater flexibility and small fixed monthly repayment options under Unsecured Business Finance.",
            iconarrow: <GoArrowUpRight />,
            path: "/services/unsecured_business_finance",

        },
        {
            icon: <FcCustomerSupport />,
            img: BusinessCashAdvance,
            title: "Business Cash Advance",
            description: "Short-term cash advance for SMEs without fixed payments, terms, or any security. Borrow from £5,000 to £200,000 and pay back the advances with a small percentage from your future credit/debit sales.",
            iconarrow: <GoArrowUpRight />,
            path: "/services/business_cash_advance",

        },
        {
            icon: <FaHouseDamage />, 
            img: PropertyFinance,
            title: "Property Finance",
            description: "For commercial, residential, or hybrid-land use, property finance ensures all the cost borne for land, development, or refurbishment is easily achievable. Explore the best property finance options for your venture.",
            iconarrow: <GoArrowUpRight />,
            path: "/services/property_finance",

        }
        ,
        {
            icon: <IoDocumentsOutline />, 
            img: RevolvingCreditFacility,
            title: "Revolving Credit Facility",
            description: "Revolving Credit Facility is designed to support you with managing your cash flow and there is a facility to draw at any time and you can only pay interest when you use it. It is a flexible funding solution offering a market leading rate starting from 0.05% per day.",
            iconarrow: <GoArrowUpRight />,
            path: "/services/revolving_credit_facility",

        },
        {
            icon: <IoDocumentsOutline />, 
            img: InvoiceFinance,
            title: "Invoice Finance",
            description: "Use unpaid invoices to receive immediate cash Invoice finance is a novel type of loan that helps businesses leverage their due invoices by lenders and provides quick monetary support for SMEs for their business expenditures.",
            iconarrow: <GoArrowUpRight />,
            path: "/services/invoice_finance",

        },
        {
            icon: <IoDocumentsOutline />, 
            img: AssetFinance,
            title: "Asset Finance",
            description: "Access easy and simplified cash flow to acquire business legitimate tools (vehicles & equipment) through Asset Finance. No need to outright put all your money on buying assets. Rent and let them pay for themselves.",
            iconarrow: <GoArrowUpRight />,
            path: "/services/asset_finance",

        },
    ];

    const Carousel = () => {
        const settings = {
            dots: true,
            infinite: true,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 5000,
            cssEase: "linear",
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };


        return (
            <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                    delay: 0.4,
                    y: { type: "spring", stiffness: 60 },
                    opacity: { duration: 0.2 },
                    ease: "easeIn",
                    duration: 1
                }}
                className="mx-[1rem] md:mx-[5rem] my-[3rem]">
                <Slider {...settings} >
                    {data.map((item, index) => (

                        <div className='card-box min-h-[24.4rem] sm:h-[24.4]' key={index}>
                            <div className="card min-h-[23rem] sm:h-[23rem]" >
                                <div className="card-icon bg-transparent rounded-full w-[5rem] h-[5rem] mx-auto py-4">
                                    <img src={item?.img} alt="carousel-icon" />
                                </div>
                                <h3 className="card-title font-square">{item.title}</h3>
                                <p className="card-description text-sm font-square">{item.description}</p>
                                <Link to={item?.path}>
                                    <div className="card-iconarrow">{item.iconarrow}</div>
                                </Link>
                            </div>
                        </div>
                    ))}
                </Slider>
            </motion.div>
        );
    };

    return (
        <div className='mt-24'>
            <ScrollToTopOnMount/>
            <div className="container-home-1-bo mx-[1rem] md:mx-[5rem] h-auto lg:h-3/4 flex flex-col items-center justify-center">
                <div className=" container-home-1-box flex flex-col lg:flex-row w-full">
                    <div className="w-full lg:w-1/2">
                        <div className="bg-white px-3 md:px-8 py-[1rem] md:py-[2rem] flex flex-col justify-center">
                            <motion.div
                                viewport={{ once: true }}
                                initial={{ y: 50, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{
                                    delay: 0.4,
                                    y: { type: "spring", stiffness: 60 },
                                    opacity: { duration: 0.2 },
                                    ease: "easeIn",
                                    duration: 1
                                }}
                                className="w-full flex items-center text-black">
                                <img src={title_vector} alt='Title Vector' className="mr-4 w-5 md:w-8" />
                                <p className="text-lg lg:text-xl font-square font-bold my-4 text-darkYellow">Re think business finance</p>
                            </motion.div>

                            <motion.h1
                                viewport={{ once: true }}
                                initial={{ y: 50, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{
                                    delay: 0.4,
                                    y: { type: "spring", stiffness: 60 },
                                    opacity: { duration: 0.2 },
                                    ease: "easeIn",
                                    duration: 1
                                }}
                                className="text-2xl my-1 lg:text-4xl font-bold text-slate-700">
                                Get Faster And Easier Access To Funds For <span className='text-darkYellow'>Growth Beyond Limitations</span>
                            </motion.h1>

                            <motion.p
                                viewport={{ once: true }}
                                initial={{ y: 50, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{
                                    delay: 0.4,
                                    y: { type: "spring", stiffness: 60 },
                                    opacity: { duration: 0.2 },
                                    ease: "easeIn",
                                    duration: 1
                                }}
                                className="my-4 text-gray-700 text-base lg:text-lg ">
                                Select the ideal financing solution for your business capital requirements with the lowest interest rates and utmost transparency. Obtain secure and reliable loans ranging from £1,000 to £10 million within a few days.
                            </motion.p>

                            <ul className=' list-decimal text-gray-700 font-square md:ms-10'>
                                <li className='font-medium'>&#11162; Match with 50+ lenders.</li>
                                <li className='font-medium'>&#11162; Services Across UK.</li>
                                <li className='font-medium'>&#11162; Access to funds from £1,000 to £10m.</li>
                            </ul>

                            <motion.div
                                viewport={{ once: true }}
                                initial={{ y: 50, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{
                                    delay: 0.4,
                                    y: { type: "spring", stiffness: 60 },
                                    opacity: { duration: 0.2 },
                                    ease: "easeIn",
                                    duration: 1
                                }}
                                className="mt-8 flex flex-col lg:flex-row">
                                <Link to="/contact">
                                    <button
                                        className="px-6 lg:px-8 py-3 font-bold rounded-full mb-4 lg:mb-0 lg:mr-4 text-white hover:text-darkYellow bg-darkYellow hover:bg-white transition-all duration-200 ease-in"
                                        style={{ border: '2px solid #ff9408' }}
                                    >
                                        Get a Quote <i class="fa-solid fa-arrow-trend-up"></i>
                                    </button>
                                </Link>

                                {/* <Link to='/about'>
                                    <button
                                        className="px-6 lg:px-8 py-3 font-bold rounded-full bg-white hover:bg-darkYellow text-darkYellow hover:text-white transition-all duration-200 ease-in"
                                        style={{ border: ' 2px solid #ff9408' }}
                                    >
                                        About Us <i class="fa-solid fa-arrow-trend-up"></i>
                                    </button>
                                </Link> */}

                            </motion.div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 relative">
                        <div className="bg-white p-8 lg:p-12 h-full flex items-center justify-center">
                            
                            <img
                                viewport={{ once: true }}
                                initial={{ y: 50, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{
                                    delay: 0.4,
                                    y: { type: "spring", stiffness: 60 },
                                    opacity: { duration: 0.2 },
                                    ease: "easeIn",
                                    duration: 1
                                }}
                                src={Homebaner}
                                alt="Illustration"
                                className="max-w-full h-[90rem] "
                                style={{ height: 'auto', maxWidth: '100%' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* cards carousel section */}
            <div className="carousel-container mx-[1rem] px-10">

                <motion.div
                    viewport={{ once: true }}
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                        delay: 0.4,
                        y: { type: "spring", stiffness: 60 },
                        opacity: { duration: 0.2 },
                        ease: "easeIn",
                        duration: 1
                    }}
                    className="w-full flex justify-center items-center text-black mt-10">
                    <img src={title_vector} alt={"Title Vector"} className=" mr-2 sm:mr-4 w-8" />
                    <p className="text-lg lg:text-2xl font-semibold text-darkYellow">Featured Service</p>
                </motion.div>

                <motion.h1
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                        delay: 0.4,
                        y: { type: "spring", stiffness: 60 },
                        opacity: { duration: 0.2 },
                        ease: "easeIn",
                        duration: 1
                    }}
                    className=' text-2xl sm:text-4xl text-slate-700 md:w-1/2 mx-auto font-semibold text-center mt-8'
                >
                    {/* Empowering You with Loan Knowledge and Comparison Tools */}
                    Explore Our Funding Options
                </motion.h1 >

                <motion.p
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                        delay: 0.4,
                        y: { type: "spring", stiffness: 60 },
                        opacity: { duration: 0.2 },
                        ease: "easeIn",
                        duration: 1
                    }}
                    className='mt-8 text-center font-square md:w-[70%] mx-auto '>
                    Explore bespoke funding opportunities to support all business cash flow needs. We are passionate supporters of entrepreneurs who refuse to give up regardless of any funding challenges. Get all the cash support your business requires with our distinct funding services empowered by trusted lenders.
                </motion.p>
                <Carousel />
            </div>


            <HowItWorks />
            <LoanCalculatorComponent />
            <WhyChooseus/>
            <TestimonialSlider />
            {/* <PartnerLogo /> */}

        </div>
    )
}

export default Home
