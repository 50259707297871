import React from 'react';
import BlogCard from './BlogCard';
import Banner from '../../components/Banner'

// import blogbanner from '../../images/blog_banner.png';
import blogbanner from '../../images/Blog/Blog_banner.png';

// import Blog_6 from '../../images/Blog/Image_06.jpg';
import Blog_1 from '../../images/Blog/blog_1.jpg';
import Blog_2 from '../../images/Blog/blog_2.jpg';
import Blog_3 from '../../images/Blog/Blog_3.jpg';
import blog_4 from '../../images/Blog/blog_4.jpg';
import blog_5 from '../../images/Blog/blog_5.jpg';
import blog_6 from '../../images/Blog/blog_6.jpg';
import ScrollToTopOnMount from '../ScrollToTop';

const Blogs = () => {
    const blogPosts = [
        {
            title: 'Factors That Influence Business Loan Interest Rates',
            date: 'May 13, 2024',
            author: 'Rajan',
            excerpt: 'One of the most important factors when applying for a business loan is the interest rate. The interest rate determines the cost of borrowing...',
            imageUrl: Blog_1, // Replace with actual image URL
            url: 'https://www.mahindrafinance.com/blogs/business-loan/factors-influencing-business-loan-interest-rates'
        },
        {
            title: 'Offline Vs Online Business Loan: Which One To Choose',
            date: 'June 17, 2024',
            author: 'Rajan',
            excerpt: 'When it comes to securing a business loan, convenience and efficiency are important. That’s where online business loans come in handy. They offer a quick and easy way to borrow money...',
            imageUrl: Blog_2,
            url: "https://www.mahindrafinance.com/blogs/business-loan/offline-vs-online-business-loan",
        },
        {
            title: 'Understanding Credit Score Range: What Each Score Means',
            date: 'July 10, 2024',
            author: 'Rajan',
            excerpt: 'Introduction Your credit score is a crucial factor in determining your financial health. It represents your creditworthiness and can have a significant impact on your ability to secure loans, get favo...',
            imageUrl: Blog_3,
            url: "https://www.mahindrafinance.com/blogs/credit-score/what-do-credit-score-ranges-mean"
        },
        {
            title: 'How To Use Credit Score To Your Advantage & Make Better Financial Decisions',
            date: 'June 28, 2024',
            author: 'Rajan',
            excerpt: 'Your credit score is a powerful financial tool that can significantly impact your life. It determines your eligibility for loans, credit cards, and other...',
            imageUrl: blog_4,
            url: "https://www.mahindrafinance.com/blogs/credit-score/how-to-use-credit-score-to-your-advantage"
        },
        {
            title: 'How To Get A Used Car Loan With A Bad Credit Score',
            date: 'June 15, 2024',
            author: 'Rajan',
            excerpt: 'Owning a car is not just a luxury but also a necessity in today’s fast-paced world. However, obtaining a car loan can be challenging if you...',
            imageUrl: blog_5,
            url: "https://www.mahindrafinance.com/blogs/used-car-loan/how-to-get-a-used-car-loan-with-bad-credit-score"
        },
        {
            title: 'Personal Loans For Higher Education In India: From Dreams To Reality',
            date: 'July 5, 2024',
            author: 'Rajan',
            excerpt: 'Dreaming of a brighter future? Pursuing higher education is the first step towards realising your aspirations. Whether you want to study in India or...',
            imageUrl: blog_6,
            url: "https://www.mahindrafinance.com/blogs/personal-loan/personal-loan-for-higher-education"
        },
    ];

    return (
        <div className='mt-[5rem]'>
            <ScrollToTopOnMount />
            <Banner name="Blogs" bannerImg={blogbanner} />

            {/* Blogs  */}
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 my-10 md:gap-6 mx-[1rem] md:mx-[5rem]">
                {blogPosts.map((post, index) => (
                    <BlogCard
                        key={index}
                        title={post.title}
                        date={post.date}
                        author={post.author}
                        excerpt={post.excerpt}
                        imageUrl={post.imageUrl}
                        url={post.url}
                    />
                ))}
            </div>

        </div>

    );
};

export default Blogs;
