import React, { useState } from 'react';
import './Contact.css';
import contactBanner from '../../images/products/contact_banner.png';
import { LuPhone } from "react-icons/lu";
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineMailOpen } from "react-icons/hi";
import Banner from '../../components/Banner';
import ScrollToTopOnMount from '../ScrollToTop';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    country: '',
    message: ''
  });
  const [condition, setCondition] = useState("1");
  const [formData1, setFormData1] = useState({
    name: '',
    phone: '',
    email: '',
    country: '',
    message: '',
    funding: '',
    financeFor: '',
    duration: '',
    businessStatus: '',
    businessName: ''
  });
const [condition1 ,setconditon]=useState("1")
const [application,setapplication]=useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formData1, [name]: value });
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://127.0.0.1:8000/api/submit_application/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        setCondition("2");
        alert(result.message);
      } else {
        alert('Failed to send the message. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleNextStep = () => {
    setconditon("433")
    setCondition("3");
  };

  const handleStepChange = (step) => {
    setCondition(step);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://maestro123.pythonanywhere.com/api/emailNewRegistratinos/', { // Update with your API endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      setconditon("2")
      alert(result.message);
    } catch (error) {
      alert('Failed to send the message. Please try again.');
    }
  };
  const callingmethod =()=>{
    setconditon("33")
  }
 
  return (
    <div className='mt-20'>
      <ScrollToTopOnMount />
      <Banner name={'Contact Us'} bannerImg={contactBanner} />

      {condition1 === "2" ? (
        <div className="quote-section text-center py-10 bg-lightGray">
          <h2 className="text-2xl font-bold mb-4">Get a quote</h2>
          <p className="mb-6">Fill the form and get a free-of-cost quote from us.</p>
          <div className="py-6 bg-white shadow-md rounded-md inline-block">
            <p>Thanks for contacting us! We will get in touch with you shortly.</p>
            <p>In the meantime, you can complete your application by clicking on the button below.</p>
            <button className="mt-4 px-6 py-3 bg-darkBlue text-white rounded-full" onClick={handleNextStep}>
              COMPLETE APPLICATION FORM HERE
            </button>
          </div>
        </div>
      ) : condition1 === "1" ? (
        <div className="flex flex-wrap justify-between xl:px-[5rem] py-[2rem] w-[100%]">
          <div className="flex flex-wrap justify-between w-[100%] lg:w-[60%]">
            <div className="shadow p-10 rounded w-full">
              <h1 className='text-darkYellow font-semibold text-left text-2xl md:text-4xl border-b border-dashed border-gray-400 py-6'>Get in Touch with us</h1>
              <form onSubmit={handleSubmit} className='my-10'>
                {/* Form Fields */}
                <div className="flex flex-col md:flex-row gap-4 my-4">
                  <div className='flex flex-col w-full mx-5'>
                    <label htmlFor="name" className='text-lg font-semibold text-slate-700'>Name </label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" id='name' className='rounded-full border border-gray-400 outline-btnGreen px-5 py-3 my-4' />
                  </div>
                  <div className='flex flex-col w-full mx-5'>
                    <label htmlFor="phone" className='text-lg font-semibold text-slate-700'>Phone </label>
                    <input type="number" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone" id='phone' className='rounded-full border border-gray-400 outline-btnGreen px-5 py-3 my-4' />
                  </div>
                </div>

                {/* Additional Form Fields */}
                <div className="flex flex-col md:flex-row gap-4 my-4">
                  <div className='flex flex-col w-full mx-5'>
                    <label htmlFor="email" className='text-lg font-semibold text-slate-700'>Email </label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" id='email' className='rounded-full border border-gray-400 outline-btnGreen px-5 py-3 my-4' />
                  </div>
                  <div className='flex flex-col w-full mx-5'>
                    <label htmlFor="country" className='text-lg font-semibold text-slate-700'>Country </label>
                    <input type="text" name="country" value={formData.country} onChange={handleChange} placeholder="Country" id='country' className='rounded-full border border-gray-400 outline-btnGreen px-5 py-3 my-4' />
                  </div>
                </div>

                <div className='w-[100%] md:px-5'>
                  <label htmlFor="message" className='text-lg font-semibold text-slate-700'>Message</label>
                  <textarea id='message' name='message' value={formData.message} onChange={handleChange} placeholder="Enter Your Message " className='border border-gray-400 w-full rounded-3xl px-5 py-4 outline-btnGreen my-4 min-h-[9rem]'></textarea>
                </div>

                <button type="submit" className='bg-darkYellow text-white hover:text-darkYellow hover:bg-white border-2 border-darkYellow px-6 py-3 rounded-full mx-4 duration-500'>
                  SEND MESSAGE
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : condition === "3" ? (
        <div className="application-form-container py-10 flex justify-center items-center">
        <div className="application-form w-full max-w-md">
         
          <form>
            {condition === "3" ? (
              <>
                <div className='mb-4'>
                  <label htmlFor="funding" className='text-lg font-semibold text-slate-700'>How much funding do you require?</label>
                  <input 
                    type="text" 
                    name="funding" 
                    value={formData.funding} 
                    onChange={handleChange1} 
                    placeholder="£" 
                    className='border border-gray-400 w-full rounded-md px-5 py-2 my-2' 
                  />
                </div>
                <div className='mb-4'>
                  <label htmlFor="financeFor" className='text-lg font-semibold text-slate-700'>What is the finance for?</label>
                  <select 
                    name="financeFor" 
                    value={formData.financeFor} 
                    onChange={handleChange1} 
                    className='border border-gray-400 w-full rounded-md px-5 py-2 my-2'
                  >
                    <option value="Cashflow">Cashflow</option>
                    <option value="Working Capital">Working Capital</option>
                  </select>
                </div>
                <div className='mb-4'>
                  <label htmlFor="duration" className='text-lg font-semibold text-slate-700'>How long will you need funding for?</label>
                  <select 
                    name="duration" 
                    value={formData.duration} 
                    onChange={handleChange1} 
                    className='border border-gray-400 w-full rounded-md px-5 py-2 my-2'
                  >
                    <option value="1 month">1 month</option>
                    <option value="3 months">3 months</option>
                  </select>
                </div>
                <button 
                  type="button" 
                  className='bg-darkYellow text-white hover:text-darkYellow hover:bg-white border-2 border-darkYellow px-6 py-3 rounded-full mt-4 duration-500' 
                  onClick={() => handleStepChange("4")}
                >
                  Next
                </button>
              </>
            ) : (
              <>
                <div className='mb-4'>
                  <label htmlFor="businessStatus" className='text-lg font-semibold text-slate-700'>The legal status of your business?</label>
                  <select 
                    name="businessStatus" 
                    value={formData.businessStatus} 
                    onChange={handleChange1} 
                    className='border border-gray-400 w-full rounded-md px-5 py-2 my-2'
                  >
                    <option value="Limited Company (LTD)">Limited Company (LTD)</option>
                    <option value="Sole Trader">Sole Trader</option>
                  </select>
                </div>
                <div className='mb-4'>
                  <label htmlFor="businessName" className='text-lg font-semibold text-slate-700'>Your Business Name</label>
                  <input 
                    type="text" 
                    name="businessName" 
                    value={formData.businessName} 
                    onChange={handleChange1} 
                    placeholder="Business Name" 
                    className='border border-gray-400 w-full rounded-md px-5 py-2 my-2' 
                  />
                </div>
                <button 
                  type="button" 
                  className='bg-darkYellow text-white hover:text-darkYellow hover:bg-white border-2 border-darkYellow px-6 py-3 rounded-full mt-4 duration-500' 
                  onClick={() => handleStepChange("1")}
                >
                  Submit Application
                </button>
              </>
            )}
          </form>
        </div>
      </div>
      
      
      ) :null}
    </div>
  );

};

export default Contact;
