import React from 'react';
import BanerImage from '../../../images/products/product_banner.png';
import FAQItem from '../../FAQs/FAQItem';
import Banner from '../../../components/Banner';
import ScrollToTopOnMount from '../../ScrollToTop';

const faqData=[
    {
        question:'Do the interest rate vary between secured and unsecured loan ?',
        answer:"Unsecured loans usually have higher interest rates since they are made available without any security.",
    },
    {
        question:'What happens in case of default repayments?',
        answer:"In case of default repayments under unsecured loans, lenders can still take legal action against the intentional defaulters. ",
    },
    {
        question:'Do I need to provide any other guarantee to get an unsecured loan?',
        answer:`Lenders sometimes demand a personal guarantee if the applicant is seeking an unsecured loan amount. Personal loans lower the risk from the lender's perspective and help them make the decision quicker to sanction the loan. 
Some lenders do not ask for any asset/collateral or personal guarantee, however this usually rakes up the interest rates. `,
    },
    {
        question:'Can I use funds from an unsecured loan to refinance other loans?',
        answer:"Yes, subject to eligibility and application, you may be able to take advantage of this aspect of unsecured loans.",
    },
    {
        question:'Interested in other finance loan options?',
        answer:`Glance over at some of our other finance tools that may benefit you more. Business Cash Advance, Property FInance, Recovery Loan Scheme, Revolving Credit Facility`,
    },
]

const UnsecruedFinance = () => {
    return (
        <div className='mt-20'>
            <ScrollToTopOnMount/>
            <Banner name={'Unsecured Business Finance'} bannerImg={BanerImage}/>

            {/* details */}

            <div className='my-10 mx-10 sm:mx-20'>
                <section className="max-w-4xl mx-auto mb-12">
                    <h1 className="text-3xl font-bold text-gray-800 mb-4">WHAT IS UNSECURED BUSINESS FINANCE?</h1>
                    <p className="text-lg text-gray-700 mb-6">
                        Unsecured loans allow small and medium-sized businesses to borrow up to £250,000 from lenders without needing to provide any formal security like a home or other assets. They're a great financial tool for those with few or no assets, hesitant to risk their assets online, or businesses needing quick cash flow to grow rapidly.
                    </p>
                    <p className="text-lg text-gray-700 mb-6">
                        Put simply, unsecured loans are popular because they don’t require borrowers to risk their assets. In contrast, secured loans need assets as collateral to get funds. This makes unsecured loans a more accessible option for businesses.
                    </p>
                    <p className="text-lg text-gray-700 mb-6">
                        Unsecured business loans empower ambitious businesses to achieve their goals and values without compromising on security. They help businesses effectively manage cash flow, invest wisely, refurbish or renovate premises, buy newer equipment and machinery, and handle uncertainties.
                    </p>
                    <p className="text-lg text-gray-700 mb-6">
                        If you are a business that needs some cash flow to boost your operations or for any other legitimate business purposes and want to get a loan without meeting collateral/security criteria, Unsecured Business Finance might be the perfect financial solution for you.
                    </p>
                </section>

                <section className=" mb-12 max-w-4xl mx-auto">
                    <h2 className="text-3xl font-semibold text-gray-800 mb-4">WHO CAN APPLY FOR UNSECURED LOANS?</h2>
                    <p className="text-lg text-gray-700 mb-6">
                        To get unsecured loans in the UK, you need to meet these key requirements:
                    </p>
                    <ol className='text-lg text-gray-700 mb-6'>
                        <li className=' list-decimal ml-10'>Be a UK trading business with a UK-based bank account.</li>
                        <li className=' list-decimal ml-10'>Have a turnover of at least £10,000.</li>
                        <li className=' list-decimal ml-10'>Be a limited company, limited liability partnership (LLP), or a registered sole trader in the UK.</li>
                        <li className=' list-decimal ml-10'>Have been operating for more than 4 months.</li>
                    </ol>
                    <p className='text-lg text-gray-700 mb-6'>Businesses of any size can apply if they meet these standards and the lender’s terms and conditions.</p>
                    <ul className='text-lg text-gray-700'>
                        Lenders may also check:
                        <li className=' list-disc ml-10'>Annual turnover</li>
                        <li className=' list-disc ml-10'>Profit/loss history</li>
                        <li className=' list-disc ml-10'>Bank statements</li>
                        <li className=' list-disc ml-10'>Trading history</li>
                        <li className=' list-disc ml-10'>Credit score</li>
                    </ul>
                </section>

                {/* Call to Action */}
                <section className=" max-w-4xl mx-auto py-10">
                    <h2 className="text-3xl font-semibold text-gray-700 mb-4">WHAT ARE THE ADVANTAGES AND DISADVANTAGES OF UNSECURED BUSINESS FINANCE?</h2>
                    <div>
                        
                        <ul className='my-3'>
                        <h3 className='text-2xl'>Advantages</h3>
                            <li className=' list-disc ml-10'>Faster capital transfer than other finance options.</li>
                            <li className=' list-disc ml-10'>No asset/collateral risk.</li>
                            <li className=' list-disc ml-10'>Pre-planned interest and repayment tenure.</li>
                            <li className=' list-disc ml-10'>Only a small upfront cost to avail loan.</li>
                        </ul>
                        
                        <ul className='my-3'>
                        <h3 className='text-2xl'>Disadvantages</h3>
                            <li className=' list-disc ml-10'>More risk for the lenders, hence personal guarantee requirements.</li>
                            <li className=' list-disc ml-10'>Higher interest rates than secured loans.</li>
                            <li className=' list-disc ml-10'>Bad impression on credit score in case of repeated default repayments.</li>
                            <li className=' list-disc ml-10'>Small loan amount with shooter repayment period than in case of secured loans. </li>
                        </ul>
                    </div>
                </section>
            </div>

            <section className=" max-w-4xl mx-auto py-10">
                <h2 className='my-4 text-xl text-gray-700'>FREQUENTLY ASKED QUESTIONS</h2>
                {
                    faqData && faqData?.map((item,index)=>(
                        <FAQItem key={index} question={item?.question} answer={item?.answer}/>
                    ))
                }
                    {/* <FAQItem/> */}
            </section>
        </div>
    );
}

export default UnsecruedFinance;
