// // src/ScrollToTop.js
// import React, { useState, useEffect } from 'react';

// const ScrollToTop = () => {
//   const [isVisible, setIsVisible] = useState(true);

//   // Show button when page is scrolled up to a certain distance
//   const toggleVisibility = () => {
//     if (window.pageYOffset > 0) {
//       setIsVisible(true);
//     } else {
//       setIsVisible(true);
//     }
//   };

//   // Set the top coordinate to 0 and scroll to top smoothly
//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth',
//     });
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', toggleVisibility);
//     return () => window.removeEventListener('scroll', toggleVisibility);
//   }, []);

//   return (
//     <div className="fixed bottom-12 right-12 ">
//       {isVisible && (
//         <button
//           onClick={scrollToTop}
//           className="px-5 py-3 text-3xl bg-btnGreen text-white rounded-full shadow-md hover:bg-blue-700 focus:outline-none"
//         >
//           ↑
//         </button>
//       )}
//     </div>
//   );
// };

// export default ScrollToTop;


import React, { useEffect, useRef } from 'react';

const ScrollToTopOnMount = () => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return <div ref={ref} />;
};

export default ScrollToTopOnMount;

