import React from 'react';
import ScrollToTopOnMount from '../ScrollToTop';

const PrivacyPolicy = () => {
  return (
    <div className="my-[8rem] mx-[1rem] sm:mx-[2rem] md:mx-[5rem]">
      <ScrollToTopOnMount />
      <h1 className='text-3xl text-darkYellow text-center font-bold'>Privacy Policy</h1>
      <p className='text-sm font-semibold text-gray-700'>Last updated: April 07, 2022</p>
      {/* <p className='text-gray-700 font-bold text-xl'>Thank you for visiting Maestro Finance</p> */}

      <section className='mx-[1rem]  md:mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>Introduction</h2>
        <p className='text-xl text-slate-800 my-6'>
          This privacy policy sets out how Maestro Finance Limited uses
          and protects any information that you give Maestro Finance when
          you use this website
        </p>

        <p className='text-xl text-slate-800 my-6'>
          Maestro Finance Limited (“we”, “us”, “our”, “Maestro
          Finance”) take data protection responsibly and we are committed
          to taking care of your business data and any data that may be
          used to identify individuals’ personal data in the course of
          operating our service. This Privacy Policy, along with our Cookie
          Policy, explains how we collect, process, store and disclose
          personal data. It applies to everyone who visits the website: staff,
          clients, customers, suppliers, partners (introducers and lenders),
          business contacts and visitors. We may change this policy from
          time to time by updating this page.
        </p>

        <p className='text-xl text-slate-800 my-6'>
          By choosing to use our services, you accept the practices outlined
          in our Privacy Policy. If you have questions or concerns regarding
          this policy, please contact us at <a href="mailto:contact@maestrofinance.co.uk" className='text-blue-800 border-b border-blue-800'>contact@maestrofinance.co.uk</a>.
        </p>
        <p className='text-xl text-slate-800 my-6'>
          Here are the details that the Regulation (EU) 2016/679 of the
          European Parliament and of the Council of 27 April 2016 on the
          protection of natural persons with regards to the processing of
          personal data and on the free movement of such data, known as
          General Data Protection Regulation (GDPR) and before 25 May
          2018 the Data Protection Act 1998 says we have to give you as a
          ‘data controller’: together with replacement, amending or
          supplementary laws or regulations relating to the protection of
          personal data.
        </p>
      </section>

      <section className='mx-[1rem]  md:mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>What Information We Collect</h2>
        <p className='text-xl text-slate-800 my-6'>
          We collect and process the following data about you:
          <ul className='mx-[3rem]'>
            <li className='text-xl text-slate-800 list-disc my-2'>
              <b>Identity data:</b> Including name, username or similar
              identifier.
            </li>
            <li className='text-xl text-slate-800 list-disc my-2'>
              <b>Contact data:</b>  Including email address, postal address,
              residential address and telephone numbers.
            </li>
            <li className='text-xl text-slate-800 list-disc my-2'>
              <b>Financial and business data:</b>  Including financial
              requirements (e.g. how much our customers would like to
              borrow), business information, bank statements, filed
              accounts, VAT returns, bank account information, file and
              payment details; in case of sole traders – other personal
              financial data relevant to business finance application, such
              as value of personal pension, value of property equity,
              county court judgements (personal and business), credit
              ratings (personal and business).
            </li>
            <li className='text-xl text-slate-800 list-disc my-2'>
              <b>Transaction data: </b> Including details about payments to and
              from you and other details of products / services you have
              purchased from us or products / services we have
              purchased from you.
            </li>
            <li className='text-xl text-slate-800 list-disc my-2'>
              <b>Usage data:</b>  Including information about how you use our
              website and products and services that you purchase from
              us.
            </li>
            <li className='text-xl text-slate-800 list-disc my-2'>
              <b>Marketing and communication data:</b>  Including your
              preferences in receiving marketing from us and our third
              parties and your communication preferences.
            </li>
          </ul>
        </p>

        <p className='text-xl text-slate-800 my-6'>
          To make sure we have your current personal information, please
          email us at <a href="mailto:contact@maestrofinance.co.uk" className='text-blue-800 border-b border-blue-800'>contact@maestrofinance.co.uk</a> and let us know any
          updates to your name, address, phone number and email
          address.
        </p>

        <p className='text-xl text-slate-800 my-6'>
          We also collect, use and share Aggregated data such as
          statistical or demographic data but is not considered personal
          data in law as this data will not directly or indirectly reveal your
          identity. For example, we may aggregate your Usage of a specific
          website feature.

        </p>

        <p className='text-xl text-slate-800 my-6'>
          Under GDPR, we will process your personal data lawfully and
          fairly and transparently, without adversely affecting your rights.
          We will only process your personal data if at least one of the
          following bases applies:
          <ol className='mx-[3rem]'>
            <li className='text-xl text-slate-800 my-2 list-decimal'>
              You’ve given your permission to have your data processed in
              a specific purpose.
            </li>
            <li className='text-xl text-slate-800 my-2 list-decimal'>
              We need to process your data in order to perform our
              contractual obligations, or in order to take steps at your
              request prior to entering into a contract.
            </li>

            <li className='text-xl text-slate-800 my-2 list-decimal'>
              processing is necessary for compliance with a legal
              obligation to which we are subject.
            </li>
            <li className='text-xl text-slate-800 my-2 list-decimal'>
              By processing your personal data, we will be able to protect
              your or any other natural persons vital interests.
            </li>

            <li className='text-xl text-slate-800 my-2 list-decimal'>
              processing is necessary for the performance of a task
              carried out in the public interest or in the exercise of official
              authority vested in the controller; and/or
            </li>

            <li className='text-xl text-slate-800 my-2 list-decimal'>
              processing is necessary for the purposes of the legitimate
              interests pursued by us or by a third party such as our credit
              card payment processing, except where such interests are
              overridden by the fundamental rights and freedoms of the
              data subject which require protection of personal data.
            </li>
          </ol>
        </p>
      </section>

      <section className='mx-[1rem]  md:mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>We use different methods to collect data from and about you, including:</h2>
        <ul className='mx-[3rem]'>
          <li className='text-xl text-slate-800 list-disc my-2'>
            We collect any interactions you have with us, including when
            you make an enquiry, purchase a product or service from us,
            enter a promotion or survey, give us feedback, interact with
            our social media pages, or contact us.
          </li>

          <li className='text-xl text-slate-800 list-disc my-2'>
            Third parties or publicly available sources, including existing
            or prospective providers of finance to you (in the course of
            considering whether to make an application or making an
            application to them), analytics providers such as Google,
            advertising networks, search information providers,] and
            identity and contact data from publicly available sources
            such as Companies House and the Electoral Register based
            inside the EU.
          </li>

        </ul>
      </section>


      <section className='mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>What we do with the information we gather</h2>
        <p className='text-xl text-slate-800 my-6'>
          Information about our customers is an important part of our
          business, and we are not in the business of selling it to others.
          We require this information to understand your needs and provide
          you with a better service, and in particular for the following
          reasons:
        </p>
        <ul className='mx-[3rem]'>
          <li className='text-xl text-slate-800 my-2 list-decimal'>
            Internal record keeping.
          </li>
          <li className='text-xl text-slate-800 my-2 list-decimal'>
            To correctly administer any order or products or services that
            you make.
          </li>
          <li className=' text-xl text-slate-800 my-2 list-decimal'>
            We may use the information to improve our products and
            services.
          </li>
          <li className=' text-xl text-slate-800 my-2 list-decimal'>
            We may periodically send promotional email about new
            products, special offers or other information.

          </li>
          <li className='list-decimal text-xl text-slate-800  my-2'>
            From time to time, we may also use your information to
            contact you for market research purposes.
          </li>
          <li className='list-decimal text-xl text-slate-800 my-2'>
            Help make decisions about credit and credit related services.
          </li>
          <li className='list-decimal text-xl text-slate-800 my-2'>
            Provide partner and event information.
          </li>
        </ul>

        <p className='text-xl text-slate-800 my-6'>
          We will only retain your personal data for as long as necessary to
          fulfil the purposes we collected it for (or any purpose which is not
          incompatible with those purposes), including for the purposes of
          satisfying any legal, accounting, or reporting requirements.

        </p>

        <p className='text-xl text-slate-800 my-6'>
          If you are already our customer, We’ll only contact you if it’s not
          about something similar to what was previously provided or of
          interest to you, and you will be given an opportunity to opt-out and
          unsubscribe from further communications.
        </p>

        <p className='text-xl text-slate-800 my-6'>
          If you wish to withdraw your consent, at any time, please contact
          us at <a href="mailto:contact@maestrofinance.co.uk" className='text-blue-800 border-b border-blue-800'>contact@maestrofinance.co.uk</a>, and we will delete your data
          from our systems. However, you acknowledge this may limit our
          ability to provide our products and services to you
        </p>

        <p className='text-xl text-slate-800 my-6'>
          In some cases, the collection of personal data may be a statutory
          or contractual requirement, and we will be limited in the products
          and services we can provide you if you don’t provide your
          personal data in these cases.
        </p>
      </section>

      <section className='mx-[1rem]  md:mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>Disclosure of your Information to Third Parties</h2>
        <p className='text-xl text-slate-800 my-6'>
          We may have to share your personal data with the following
          parties for the purposes below:
        </p>

        <ul className='mx-[3rem]'>
          <li className='text-xl text-slate-800 list-disc my-2'>
            Companies associated with fraud prevention.
          </li>
          <li className='text-xl text-slate-800 list-disc my-2'>
            As an alternative to buying or merging with other companies,
            we may also choose to sell our assets to others. If this
            happens, your personal data will become their property, and
            they can use your personal data in the same way as us.
          </li>
          <li className='text-xl text-slate-800 list-disc my-2'>
            We use third parties to store personal data.
          </li>
          <li className='text-xl text-slate-800 list-disc my-2'>
            We use a third party to host, manage and support our
            website. They therefore have access to any data that you
            submit via this website.
          </li>
        </ul>

        <p className='text-xl text-slate-800 my-6'>
          We require all third parties to respect the security of your personal
          data and to treat it in accordance with the law. We do not allow
          our third-party service providers to use your personal data for
          their own purposes and only permit them to process your
          personal data for specified purposes and in accordance with our
          instructions.
        </p>
      </section>

      <section className='mx-[1rem]  md:mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>Overseas Transfers</h2>

        <p className='text-xl text-slate-800 my-6'>
          We are part of a group of companies. We share infrastructure and
          functions across our business internationally. This means that
          your data might be moved to, accessed, or processed in other
          countries where we do business.
        </p>

        <p className='text-xl text-slate-800 my-6'>
          If your information is transferred to or accessible in a country
          which is not considered by the European Community to provide
          adequate protection for your rights and freedoms in relation to the
          processing of personal data (such as the USA), we will take
          alternative steps (as permitted by the GDPR) to ensure your
          information is adequately protected and that those transfers
          comply with data protection law.
        </p>

        <p className='text-xl text-slate-800 my-6'>
          We may transfer your information to other countries, including
          those outside the European Economic Area, either for storage
          purposes or if we engage suppliers, sub-contractors or third-party
          data processors who are based or have operations overseas.
        </p>

        <p className='text-xl text-slate-800 my-6'>
          In Particular, The personal data of prospective clients that in the
          group companies may hold or obtain from time to time will be
          transferred to Alpha Corporate Solutions, which acts as both a
          data processor and data controller (for different purposes) to help
          review and assess data on prospective clients provided by the
          Group companies. Data processed by Alpha Corporate Solutions
          may be used to help one or more Group companies determine
          whether they wish to do business with a prospective client and on
          what terms.
        </p>

        <p className='text-xl text-slate-800 my-6'>
          Our Group companies may also transfer to brokers, Lender’s
          point of contact for us and introducers personal data relating to
          prospective clients (as part of other data relating to them) to which
          they are or would be unable to offer facilities, to enable these
          brokers and introducers to identify and introduce alternative
          providers. These brokers and introducers will act as data
          controllers and some of these brokers may be located outside the
          EEA.
        </p>

      </section>

      <section className='mx-[1rem]  md:mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>Each Group company that</h2>
        <ol className='mx-[3rem]'>
          <li className='list-decimal text-xl text-slate-800 my-2'>
            shares personal data with Alpha Corporate Solutions
            provides adequate protection for the rights and freedoms of
            data subjects by entering into EU-approved model contracts
            with it as (i) a processor; and (ii) a controller, of personal
            data which is shared with it; and
          </li>
          <li className='list-decimal text-xl text-slate-800  my-2'>
            shares personal data with non-EEA brokers and introducers
            provides adequate protection for the rights and freedoms of
            data subjects by entering into EU-approved model contracts
            with each broker and introducer as a controller of personal
            data which is shared with it.
          </li>
        </ol>
      </section>

      <section className='mx-[1rem]  md:mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>Your Legal Rights</h2>
        <p className='text-xl text-slate-800 my-6'>
          You have the right to complain to the Information Commissioner’s
          Office if you do not feel that your personal data is being used
          appropriately. The ICO is the UK’s supervisory authority for data
          protection issues (<a href="www.ico.org.uk" className='text-blue-800'>www.ico.org.uk</a>).
        </p>

        <p className='text-xl text-slate-800 my-6'>
          If you do have a problem, question or concern about our use of
          your personal data, we would really appreciate the chance to try
          to help you before you approach the ICO, so please feel free to
          contact us in the first instance using our contact details.
        </p>

        <p className='text-xl text-slate-800 my-6'>
          You have the right to:
          <ul className='mx-[3rem]'>
            <li className='text-xl text-slate-800 list-disc my-2'>
              <b>Request access</b> You have the right to ask for a copy of the data
              we hold on you and to check that we are handling it legally. <br />

              When you ask us for information, we will give you a response
              within one month. If it takes longer, we will let you know. We can
              delay giving you this information by two months if your request is
              complex or contains multiple requests. In such instances we will
              inform you within one month of the receipt of the request and
              explain why the extension is necessary. <br />

              When we receive a subject access request, we will provide a copy
              of the information held for you free of charge. If you make
              additional requests, we may charge a reasonable fee to comply
              with your request. This does not mean that we will charge for all
              subsequent access requests rather that We reserves the right to
              charge a fee based on the administrative cost of providing the
              information. <br />

              If we believe a request for data is manifestly unfounded or
              excessive, particularly if the request is repetitive, we may charge
              a “reasonable fee” which will be decided on a case by case basis.
              In certain circumstances we may even refuse to respond to such
              requests. <br />
            </li>

            <li className='text-xl text-slate-800 list-disc my-2'>
              <b>Request correction</b> This will allow you to correct any inaccurate
              data we hold about you. Subject to verifying the accuracy of the
              new data you provide to us
            </li>

            <li className='text-xl text-slate-800 list-disc my-2'>
              <b>Request erasure</b> This enables you to ask us to delete or remove
              personal data where there is no good reason for us continuing to
              process it. You also have the right to ask us to delete your
              personal data where you have successfully exercised your right to
              object to processing, where we may have processed your
              information unlawfully or we are required to erase your personal
              data to comply with local law. However, we may not always be
              able to comply with your request of erasure for specific legal
              reasons which will be notified to you, if applicable, at the time of
              your request
            </li>

            <li className='text-xl text-slate-800 list-disc my-2'>
              <b>Withdraw Consent</b> at any time where we are relying on consent
              to process your personal data. However, this will not affect the
              lawfulness of any processing carried out before you withdraw your
              consent. If you withdraw your consent, we may not be able to
              provide certain products or services to you. We will advise you if
              this is the case at the time you withdraw your consent.
            </li>
          </ul>
        </p>
      </section>

      <section className='mx-[1rem]  md:mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>Notice to Staff:</h2>

        <p className='text-xl text-slate-800 my-6'>
          All staff have an obligation to read and adhere to all relevant
          Maestro Finance Limited Policies. Personal information held
          about staff will be stored securely and will only be accessible by
          authorised departments. All Maestro Finance hardware and
          software is the property of Maestro Finance. When leaving
          employment all staff are required to return all hardware, including
          access cards. If staff use the facilities for personal use, they
          understand that Maestro Finance has the right to access the
          hardware and software at any time without notice. If
          communications are of a personal nature staff should clearly
          subject the content as personal. However, Maestro Finance will
          still have the right to access these if needed.
        </p>
      </section>

      <section className='mx-[1rem] md:mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>Complaints:</h2>
        <p className='text-xl text-slate-800 my-6'>
          If you’re unhappy with our service, please contact us. We’re doing
          everything we can to ensure your satisfaction.
        </p>

        <p className='text-xl text-slate-800 my-6'>
          We will do everything we can to make sure that our service meets
          your requirements. If you have a complaint about our service,
          please tell us about it so that we can try to put things right.
        </p>
      </section>

      <section className='mx-[1rem] md:mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>How to complain</h2>
        <h4>To make a complaint, you can:</h4>

        <ul className='mx-[3rem]'>
          <li className='text-xl text-slate-800 list-disc my-2'>
            Call us on 020 79936150. We are available from 9am to 5pm
            Monday to Friday (please note that we are closed on all UK
            public and bank holidays).
          </li>
          <li className='text-xl text-slate-800 list-disc my-2'>
            Email us at <a href="mailti:contact@maestrofinance.co.uk" className='text-blue-800 border-b border-blue-800'>contact@maestrofinance.co.uk</a>
          </li>
          <li className='text-xl text-slate-800 list-disc my-2'>
            Write to: The Complaints Department, Maestro Finance
            Limited, 16 Upper Woburn Place, London, WC1H 0AF.
          </li>

        </ul>
      </section>

      <section className='mx-[1rem] md:mx-[3rem] my-[1.5rem]'>
        <h2 className='text-2xl font-semibold mt-4'>How long will it take?</h2>

        <p className='text-xl text-slate-800 my-6'>
          We will do the maximum we can make sure your complaint is
          resolved within three working days. If we can’t do this, we’ll send
          you a response within five working days. We will contact you
          regularly until your complaint has been fully resolved.
        </p>

        <p className='text-xl text-slate-800 my-6'>
          Once you have been notified of our response, please allow eight
          weeks for a response from us. If you are not happy with our
          response, you may contact the Financial Ombudsman Service.
        </p>

        <h4>Contact details for the Financial Ombudsman Service:</h4>
        <p className='text-xl text-slate-800 my-6'>Financial Ombudsman Service Exchange Tower London E14 9SR</p>
        <p className='text-xl text-slate-800 my-6'>Email: <a href="mailto:complaint.info@financial-ombudsman.org.uk" className='text-blue-800 border-b border-blue-800'>complaint.info@financial-ombudsman.org.uk</a></p>
      </section>

    </div>
  );
};

export default PrivacyPolicy;
