import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Navbar.css';
import MastroLogo from '../../images/MASTRO LOGO.png'


function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {pathname}=useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const navItems = [
    { path: "/", link: "Home" },
    { path: "/about", link: "About Us" },
    { path: "/services", link: "Products" },
    { path: "/calculator", link: "Calculator" },
    { path: "/blog", link: "Blog" },
    { path: "/contact", link: "Contact Us" },
  ];


 

  return (
    <header className="bg-gray-50 fixed top-0 left-0 right-0 z-20 mb-16 md:px-[4rem] border-b">
      <nav className="px-6 py-6 max-w-7xl mx-auto flex justify-between items-center">
        <a href="/" className="text-xl font-bold">
          <img src={MastroLogo}
            className='w-[7rem] sm:w-[9rem] md:w-[10rem]'
            alt="Logo" />
        </a>

        {/* navItems for lg devices */}
        <ul className="lg:flex gap-6 text-lg hidden">
          {navItems.map(({ path, link }) => (
            <li className={`${pathname===path?"text-darkYellow":""} relative hover:text-darkYellow font-normal font-square`} key={path}>
              <NavLink
                className={({ isActive, isPending }) => (isActive ? "active" : isPending ? "pending" : "")}
                to={path}
              >
                {link}
              </NavLink>
            </li>
          ))}
        </ul>
       

        <div className="lg:hidden">
          <i className="fa-solid fa-bars text-2xl cursor-pointer" onClick={toggleMenu}></i>
        </div>
      </nav>

      <div>
        <ul
          className={`lg:hidden fixed  bg-darkYellow h-[100vh] opacity-100 gap-12 text-lg block space-y-4 px-[1rem] sm:px-[5rem] py-[5rem] 
            transition-all ease-in-out duration-500 
            ${isMenuOpen ? "  top-0 right-[0vw] w-full transition-all ease-in-out duration-500 " : " top-0 right-[-120vw] w-full "
            }`}
        >
          <i className="fa-solid absolute top-8 right-8 fa-x text-base border rounded-full px-4 py-2
       text-white cursor-pointer" onClick={toggleMenu}
          ></i>
          {navItems.map(({ path, link }) => (
            <li className={`${pathname===path?"text-yellow-400":""} text-white text-xl font-jost py-4 border-b font-bold hover:text-yellow-400`} key={path}>
              <NavLink onClick={toggleMenu} to={path}>
                {link}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
}

export default Navbar;
