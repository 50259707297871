import React from 'react';
import { motion } from 'framer-motion';
// import servicesBanner from '../images/service_banner.png';
import { FaHandPointRight } from "react-icons/fa";

const Banner = ({ name, bannerImg }) => {
  return (
    <div>
      <div className='bg-gradient-to-r from-darkYellow to-Yellow flex justify-between w-full px-[1rem] md:px-[5rem] py-10 items-center'>
        <motion.div
          viewport={{ once: true }}
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.4,
            y: { type: "spring", stiffness: 60 },
            opacity: { duration: 0.2 },
            ease: "easeIn",
            duration: 1
          }}
          className='text-white'>
          <h2 className='text-3xl font-semibold'>{name}</h2>
          <p className=' flex justify-start items-center gap-2'>MAESTRO <FaHandPointRight/> {name}</p>
        </motion.div>
        <div className='hidden md:block'>
          <img src={bannerImg} alt="Services Banner" className='w-[15rem]'/>
        </div>
      </div>
    </div>
  );
}

export default Banner;
